import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import TooltipWrapper from './Tooltip.style.jsx';

const Tooltip = ({ isVisible, tooltipPosition, labelValue, parentElement }) => {
  const parentBouncingRect = parentElement && parentElement.getBoundingClientRect();
  return ReactDOM.createPortal(
    <TooltipWrapper
      isVisible={isVisible}
      coordX={parentBouncingRect && parentBouncingRect.x}
      coordY={parentBouncingRect && parentBouncingRect.y}
    >
      <div className="tooltip__label">{labelValue}</div>
    </TooltipWrapper>,
    document.getElementById('root')
  );
};

Tooltip.propTypes = {
  isVisible: PropTypes.bool,
  tooltipPosition: PropTypes.string,
  labelValue: PropTypes.string,
};

export default Tooltip;
