import { authHeader, handleResponse } from 'helpers';
import { config } from './Config';

export const orderService = {
  fetchTotalRowNumber,
  fetchItems,
  fetchItem,
  renewOrder,
  downloadOrderFormPdf,
  downloadPreorderXls,
  rateOrder,
};

function fetchTotalRowNumber(filter) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };

  const urlFilter = JSON.stringify(filter);
  return fetch(`${config.apiUrl}/api/v2.0/order/count?options=${urlFilter}`, requestOptions).then(
    handleResponse
  );
}

function fetchItems(filter, offset, limit) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };
  const newFilter = filter;
  newFilter.offset = offset;
  newFilter.limit = limit;

  const urlFilter = JSON.stringify(newFilter);
  return fetch(`${config.apiUrl}/api/v2.0/order/list?options=${urlFilter}`, requestOptions).then(
    handleResponse
  );
}

function fetchItem(filter, offset, limit) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };
  const urlFilter = JSON.stringify(filter);

  return fetch(
    `${config.apiUrl}/api/order?filter=${urlFilter}&offset=${offset}&limit=${limit}&orderByDesc=dateCreate`,
    requestOptions
  ).then(handleResponse);
}

function renewOrder(id) {
  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(),
  };
  const requestOptions = {
    method: 'PUT',
    headers,
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/api/order/renew/${id}`, requestOptions).then(handleResponse);
}

function downloadOrderFormPdf(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(false),
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/api/order/pdf/${id}`, requestOptions).then((r) => r.blob());
}

/**
 * Order.js - Permet de télécharger la liste des précommandes au format XLS
 */
function downloadPreorderXls(since, to) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(false),
    mode: 'cors',
  };

  return fetch(
    `${config.apiUrl}/api/order/xls/preorder?since=${since}&to=${to}`,
    requestOptions
  ).then((r) => r.blob());
}

function rateOrder(orderId, rating, comment) {
  const body = JSON.stringify({
    rating,
    comment,
  });
  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(),
  };
  const requestOptions = {
    method: 'POST',
    headers,
    body,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/order/${orderId}/rating`, requestOptions).then(handleResponse);
}
