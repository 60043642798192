// import { getValueOfAttribut } from 'helpers';
import Moment from 'moment';
import flattenDeep from 'lodash.flattendeep';
import { getFormatedDate } from 'helpers/Utils';
import { store, getValueOfAttribut } from '.';
import { platformConstants, cartConstants } from '../constants';

const { STOCK_CART_KEY, ORDERED_BY_KEY, DELIVERY_MODE_KEY } = platformConstants;
const { CART_STATUS_CUSTOM } = cartConstants;

export function getCartItemByItemId(itemId, cart, getTemps = false) {
  const cartId =
    (typeof cart === 'object' || typeof cart === 'function') && cart !== null ? cart.id : cart;
  const cartItems = getCartItems(cartId);
  if (cartItems) {
    const cartItem = cartItems.find((ci) => {
      const { item_id: id, is_temps: isTemps } = ci;
      if (!getTemps) {
        return id && Number(id) === Number(itemId) && !isTemps;
      }
      return id && String(id) === String(itemId);
    });
    return cartItem;
  }
  return null;
}

export function getItemCartTotal(itemId, cart) {
  const cartItems = getCartItems(cart.id);
  if (cartItems) {
    const cartItem = getCartItemByItemId(itemId, cart, true);
    if (cartItem) {
      return cartItem.total_price;
    }
  }
  return 0;
}

export function getItemCartQuantity(itemId, cart) {
  const cartItems = getCartItems(cart.id);
  if (cartItems) {
    const cartItem = getCartItemByItemId(itemId, cart, true);
    if (cartItem) {
      return cartItem.quantity;
    }
  }
  return 0;
}

export function getCartContainer(cart) {
  return cart.containers && cart.containers.length;
}

export function getCurrentCart() {
  const { cart } = store.getState();
  const { current } = cart;
  return current;
}

export function getCurrentPreorderCart() {
  const { cart } = store.getState();
  const { currentPreorder } = cart;
  return currentPreorder;
}

export function getCurrentCartItems() {
  const { cart } = store.getState();
  const { current } = cart;
  if (current) {
    return getCartItems(current.id);
  }
  return [];
}

export function getCurrentPreorderCartItems() {
  const { cart } = store.getState();
  const { currentPreorder } = cart;
  if (currentPreorder) {
    return getCartItems(currentPreorder.id);
  }
  return [];
}

// All cart item of a cart
// Will retur all occurence of cartItems for a cart ID
export function getCartItems(cartId) {
  const { cartItems } = store.getState();
  const { collections } = cartItems;
  return flattenDeep(collections.map(({ items }) => items)).filter(
    (i) => String(i.cart_id) === String(cartId)
  );
}

// Will only return uniq occurence of cartItems
// Usefull to copy cartItems state on Cart state
export function getUniqCartItems(cartId) {
  const cartItems = getCartItems(cartId);
  return cartItems.filter(
    (ci, index, a) => a.findIndex(({ id }) => String(ci.id) === String(id)) === index
  );
}

export function getRealCartId(cartId, getTemps = false) {
  if (cartId === 'current' || cartId === 'current_preorder') {
    const realCart = getRealCart(cartId, getTemps);
    return realCart ? realCart.id : false;
  }
  return cartId;
}

export function getRealCart(cartId, getTemps = false) {
  if (cartId === 'current') {
    const current = getCurrentCart();
    if (current && Object.keys(current).length && (getTemps || (!getTemps && !current.is_temps))) {
      return current;
    }
    return false;
  }
  if (cartId === 'current_preorder') {
    const currentPreorder = getCurrentPreorderCart();
    if (
      currentPreorder &&
      Object.keys(currentPreorder).length &&
      (getTemps || (!getTemps && !currentPreorder.is_temps))
    ) {
      return currentPreorder;
    }
    return false;
  }
  const carts = getCartsById(cartId);
  return carts && carts.length && (getTemps || (!getTemps && !carts[0].is_temps))
    ? carts[0]
    : false;
}

export function getCartItemById(cartItemId, cartItemsCollections) {
  let collections = cartItemsCollections;
  if (!cartItemsCollections) {
    const { cartItems } = store.getState();
    const { collections: coll } = cartItems;
    collections = coll;
  }
  return flattenDeep(collections.filter((c) => c.items).map(({ items }) => items)).find(
    ({ id }) => String(id) === String(cartItemId)
  );
}

export function getRemainingQuantity(item, quantity, selectedPlatform) {
  const { stock } = item;
  const quantityRequested = getQuantityRequested(item, quantity, selectedPlatform);
  if (stock) {
    const remainingPackages = stock.value_packing - quantityRequested.packages;
    const remainingUnits = stock.value_cu - quantityRequested.items;

    return {
      packages: remainingPackages,
      items: remainingUnits,
    };
  }

  return null;
}

export function getQuantityRequested(item, quantity, selectedPlatform) {
  const { selectedId, platforms } = selectedPlatform;
  const currentPlatform = platforms.find((p) => p.id === selectedId);
  const { attributs } = currentPlatform;
  // default for orderedBy is true
  const orderedBy = Number(getValueOfAttribut(attributs || [], ORDERED_BY_KEY)) || 0;

  // commande par colis
  if (orderedBy === 1) {
    return {
      packages: quantity,
      items: quantity * item.pcb,
    };
  }

  // commande par unit
  return {
    packages: Math.ceil(quantity / item.pcb),
    items: quantity,
  };
}

export function getMaxQuantityOfProduct(item, selectedPlatform) {
  const { stock: productStock, is_preorder: isProductPreorder } = item;
  const { attributs } = selectedPlatform;
  // default for orderedBy is true
  if (!productStock || isProductPreorder) {
    return -1;
  }
  const orderedBy = Number(getValueOfAttribut(attributs || [], ORDERED_BY_KEY)) === 0 ? 0 : 1;
  return orderedBy ? productStock.value_packing : productStock.value_cu;
}

export function getHasApiStockLeft(item, selectedPlatform) {
  if (!item) {
    return false;
  }
  const { stock: productStock, is_preorder: isProductPreorder } = item;

  if (!productStock || isProductPreorder) {
    return true;
  }

  const maxQty = getMaxQuantityOfProduct(item, selectedPlatform);
  if (Number(maxQty) <= 0) {
    return false;
  }
  return true;
}

export function canAddToCart(item, quantityToAdd, selectedPlatform) {
  if (!item) {
    return false;
  }

  const { stock: productStock, is_preorder: isProductPreorder } = item;

  if (!productStock || isProductPreorder) {
    return true;
  }

  const maxQty = getMaxQuantityOfProduct(item, selectedPlatform);
  const stockQty = Number(maxQty) - Number(quantityToAdd);
  if (stockQty < 0) {
    return false;
  }
  return true;
}

export function allowedToOrder(item, requestedQuantity, cart, selectedPlatform) {
  if (!item.is_preorder && item.stock && cart.status !== CART_STATUS_CUSTOM) {
    const { attributs } = selectedPlatform;
    // que si le stock est insuffisant il est impossible de comander
    const valueStockCart = Number(getValueOfAttribut(attributs || [], STOCK_CART_KEY));

    // si stock cart Value == 0, on doit verifier si la quantite en stock est suffisant ou nn
    if (valueStockCart === 0) {
      // recuperer la quantite restant adequat (soit colis soit unit)
      return canAddToCart(item, requestedQuantity, selectedPlatform);
    }
  }
  return true;
}

function updateCartCountItemsByType(cart) {
  const totals = {};
  if (cart.cart_items.length) {
    cart.cart_items.forEach((cartItem) => {
      const { item_type: itemType } = cartItem;
      if (totals[itemType] === undefined) {
        totals[itemType] = 0;
      }
      totals[itemType] += 1;
    });
  }
  return Object.assign(cart, { count_items_by_type: totals });
}

function updateCartTotalsByType(cart) {
  const totals = {};
  if (cart.cart_items.length) {
    cart.cart_items.forEach((cartItem) => {
      const { item_type: itemType } = cartItem;
      if (totals[itemType] === undefined) {
        totals[itemType] = {
          total_price_vat: 0,
          total_price: 0,
          total_quantity: 0,
          total_weight_gross_package: 0,
          total_volume_package: 0,
        };
      }
      totals[itemType].total_price_vat += cartItem.total_price_vat;
      totals[itemType].total_price += cartItem.total_price;
      totals[itemType].total_quantity += cartItem.quantity;
      totals[itemType].total_weight_gross_package +=
        cartItem.item.package &&
        parseFloat(cartItem.item.package.weight_gross_package) * cartItem.quantity;
      totals[itemType].total_volume_package +=
        cartItem.item.package &&
        parseFloat(cartItem.item.package.volume_package) * cartItem.quantity;
    });
  }
  return Object.assign(cart, { totals_by_type: totals });
}

function updateCartTotalQuantity(cart) {
  let total = 0;
  if (cart.cart_items.length) {
    cart.cart_items.forEach((cartItem) => {
      total += cartItem.quantity;
    });
  }
  return Object.assign(cart, { total_quantity: total });
}

function updateCartTotalPriceVat(cart) {
  let total = 0;
  if (cart.cart_items.length) {
    cart.cart_items.forEach((cartItem) => {
      total += cartItem.total_price_vat;
    });
  }
  return Object.assign(cart, { total_price_vat: total });
}

function updateCartTotalPrice(cart) {
  let total = 0;
  if (cart.cart_items.length) {
    cart.cart_items.forEach((cartItem) => {
      total += cartItem.total_price;
    });
  }
  return Object.assign(cart, { total_price: total });
}

function updateCartReferences(cart) {
  let packages = 0;
  let items = 0;
  if (cart.cart_items.length) {
    cart.cart_items.forEach((cartItem) => {
      packages += cartItem.quantity;
      items += cartItem.item_pcb * cartItem.quantity;
    });
  }
  return Object.assign(cart, { references: { packages, items } });
}

export function updateCartFromCartItems(cartId) {
  const carts = getCartsById(cartId);
  return carts.map((cart) => {
    const cartItems = getUniqCartItems(cart.id);
    Object.assign(cart, { cart_items: cartItems });
    refreshCartValues(cart);
    return cart;
  });
}

export function getCartsById(cartId, cartsCollections) {
  let collections = cartsCollections;
  if (!cartsCollections) {
    const { cart } = store.getState();
    const { collections: coll } = cart;
    collections = coll;
  }
  return flattenDeep(collections.filter((c) => c.items).map(({ items }) => items)).filter(
    ({ id }) => String(id) === String(cartId)
  );
}

export function getCartsByStatus(cartStatus, cartsCollections) {
  let collections = cartsCollections;
  if (!cartsCollections) {
    const { cart } = store.getState();
    const { collections: coll } = cart;
    collections = coll;
  }

  return flattenDeep(collections.filter((c) => c.items).map(({ items }) => items)).filter(
    ({ status }) => String(status) === String(cartStatus)
  );
}

export function refreshCartValues(cart) {
  updateCartCountItemsByType(cart);
  updateCartTotalsByType(cart);
  updateCartTotalQuantity(cart);
  updateCartTotalPriceVat(cart);
  updateCartTotalPrice(cart);
  updateCartReferences(cart);
  return cart;
}

export function addUpdateTempsCartItem(collection, cartId, item, quantity, platform) {
  const { selectedId, platforms } = platform;
  const currentPlatform = platforms.find((p) => p.id === selectedId);
  const { attributs } = currentPlatform;
  const orderedBy = Number(getValueOfAttribut(attributs || [], ORDERED_BY_KEY)) || 0;
  const newDatas = {
    quantity,
    remaining_quantity: getRemainingQuantity(item, quantity, platform),
    total_price:
      orderedBy === 1
        ? quantity * item.applicable_price * item.pcb // Commandes par colis
        : quantity * item.applicable_price, // Commandes à l'unité
    total_price_vat:
      orderedBy === 1
        ? quantity * item.applicable_price_vat * item.pcb // Commandes par colis
        : quantity * item.applicable_price_vat, // Commandes à l'unité
  };
  const { items } = collection;
  const cartItem = items.find((i) => String(i.item_id) === String(item.id));
  if (cartItem) {
    Object.assign(cartItem, newDatas);
  } else {
    Object.assign(newDatas, {
      id: `temps-${cartId}-${item.id}`,
      is_temps: true,
      cart_id: cartId,
      item_id: item.id,
      item_pcb: item.pcb,
      item_type: item.type,
      is_waiting_for_api: false,
      item,
    });
    items.push(newDatas);
  }
  return Object.assign(collection, { items });
}

export function addRequestCartItem(collection, item) {
  const { items } = collection;
  const cartItem = items.find((i) => String(i.item_id) === String(item.id));
  if (cartItem) {
    Object.assign(cartItem, {
      is_waiting_for_api: true,
    });
  }

  return collection;
}

export function addSuccessCartItem(collection, cartItemFromApi, item) {
  const { items } = collection;
  const cartItem = items.find((i) => String(i.item_id) === String(item.id));
  if (cartItem) {
    Object.assign(cartItem, {
      cart_id: cartItemFromApi.cart_id,
      is_temps: false,
      id: cartItemFromApi.id,
      remaining_quantity: cartItemFromApi.remaining_quantity,
      is_waiting_for_api: false,
    });
  }
  return collection;
}

export function getNewTempsCartId(status, collections = null, getNumber = true) {
  const number = getNumber ? getCartsByStatus(status, collections).length : null;
  let id = '';
  switch (status) {
    case cartConstants.CART_STATUS_CURRENT_PREORDER:
      id = 'current_preorder';
      break;
    case cartConstants.CART_STATUS_CURRENT:
      id = 'current';
      break;
    case cartConstants.CART_STATUS_CUSTOM_PREORDER:
      id = 'custom_preorder';
      break;
    default:
      id = 'custom';
      break;
  }

  return number ? `${id}-${number}` : id;
}

export function getTempsCartStatus(id) {
  const idToCompare = id.indexOf('-') === -1 ? id : id.substring(0, id.indexOf('-'));
  switch (idToCompare) {
    case 'current_preorder':
      return cartConstants.CART_STATUS_CURRENT_PREORDER;
    case 'current':
      return cartConstants.CART_STATUS_CURRENT;
    case 'custom_preorder':
      return cartConstants.CART_STATUS_CUSTOM_PREORDER;
    default:
      return cartConstants.CART_STATUS_CUSTOM;
  }
}

export function addTempsCart(collection, status, tempsId = '', name = '') {
  const newCart = {
    id: tempsId === '' ? getNewTempsCartId(status, null, false) : tempsId,
    name,
    cart_items: [],
    containers: [],
    count_item_moqs: 0,
    count_not_validated_cart_items: 0,
    delivery_dates_by_type: [],
    is_preorder: status === cartConstants.CART_STATUS_CURRENT_PREORDER,
    status,
    count_items_by_type: {},
    references: {},
    total_price: 0,
    total_price_vat: 0,
    total_quantity: 0,
    is_temps: true,
    is_waiting_for_api: true,
    date_create: Moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
  };
  let { items } = collection;
  if (!items) {
    items = [];
  }
  items.push(newCart);
  return Object.assign(collection, { items, isLoading: true, isTemps: true });
}

export function updateTempsCartFromApi(collection, cartFromApi, tempsId) {
  // The parameter tempsId which we believe is a cartId could be falsy,
  // generating an error of an unfound cart.
  // We fix this by creating a cart if no cart to update is specified.
  if (!tempsId) {
    return Object.assign(collection, {
      items: [{ ...cartFromApi, is_temps: false, is_waiting_for_api: false }],
      isLoading: false,
      isTemps: false,
    });
  }
  const { items } = collection;
  const cart = items.find(({ id }) => String(id) === String(tempsId));
  const { cart_items: cartItems } = cart;
  const newCartItems = cartItems.map((ci) => Object.assign(ci, { cart_id: cartFromApi.id }));
  Object.assign(cart, {
    id: cartFromApi.id,
    cart_items: newCartItems,
    is_temps: false,
    is_waiting_for_api: false,
  });
  return Object.assign(collection, { items, isLoading: false, isTemps: false });
}

export function deleteCartInCollections(cartId, collections) {
  return collections
    .map((ci) => {
      const { items } = ci;
      if (items) {
        const newItems = items.filter(({ id }) => String(id) !== String(cartId));
        return Object.assign({}, ci, { items: newItems });
      }
      return ci;
    })
    .filter(({ items }) => items && items.length > 0);
}

export function updateCartInCollections(cart, collections) {
  return collections.map((collection) => {
    const items = collection.items.map((i) => {
      if (String(i.id) === String(cart.id)) {
        return Object.assign(i, cart);
      }
      return i;
    });
    return Object.assign(collection, { items, isLoading: false });
  });
}

export function isValidDeliveryDay(
  date,
  temperatureOrDeliveryMode,
  user,
  dayOff,
  selectedPlatform
) {
  const { attributs } = selectedPlatform;
  const platformDeliveryMode = !!Number(getValueOfAttribut(attributs || [], DELIVERY_MODE_KEY));

  if (date) {
    if (getFormatedDate(date) < getFormatedDate(new Date())) {
      return false;
    }
    const day = date.getDay();
    // Check day offs
    const isDayOff = dayOff.findIndex((d) => d.date === getFormatedDate(date)) > -1;
    if (isDayOff) {
      return false;
    }
    // Check user delivery planning
    const { delivery_plannings: deliveryPlannings } = user.client;
    if (deliveryPlannings && deliveryPlannings.length > 0) {
      const tempOrMode = platformDeliveryMode ? 'delivery_mode' : 'temperature';

      const deliveryPlanningByTemperature = deliveryPlannings.filter(
        (item) =>
          item[tempOrMode] === temperatureOrDeliveryMode &&
          String(item.platform_id) === String(selectedPlatform.id)
      );

      if (deliveryPlanningByTemperature && deliveryPlanningByTemperature.length > 0) {
        // Check if has children (substitution plannings)
        const { children } = deliveryPlanningByTemperature[0];
        let effectiveSubstitutionPlanning = null;
        if (children && children.length > 0) {
          effectiveSubstitutionPlanning = children.find(
            (d) => getFormatedDate(date) >= d.date_begin && getFormatedDate(date) <= d.date_end
          );
        }
        const { days } = effectiveSubstitutionPlanning || deliveryPlanningByTemperature[0];
        const effectiveDay = days.find((d) => Number(d.week_day) === Number(!day ? 7 : day));
        if (effectiveDay) {
          return Moment().isBefore(
            Moment(date).hours(12).minutes(0).subtract(Number(effectiveDay.hour), 'hours')
          );
        }
      }
    }
  }

  return false;
}
