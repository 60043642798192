import { authHeader, handleResponse } from 'helpers';
import { getFormatedDate } from 'helpers/Utils';
import { catalogConstants } from '../constants';
import { config } from './Config';

export const cartService = {
  fetchItems,
  fetchTotalRowNumber,
  createCart,
  deleteCart,
  validateCart,
  copyCart,
  updateCart,
  downloadCartPdf,
  renewCurrentCartWithReferencesExist,
};

function validateCart(
  cartId,
  deliveryDates,
  withDeliveryAmount = false,
  deliveryAmount = 0,
  isPreparationOnly = 0,
  cartItems = [],
  checkStockDisponibility = false
) {
  const cleanDates = {};
  if (deliveryDates) {
    Object.keys(deliveryDates).forEach((key) => {
      if (deliveryDates[key] !== '') {
        cleanDates[key] = deliveryDates[key];
      }
    });
  }

  const cartItemsToSend = cartItems.map(({ id, quantity }) => ({ id, quantity }));

  const cleanDeliveryAmount = withDeliveryAmount
    ? {
        delivery_amount: deliveryAmount,
        delivery_mode: isPreparationOnly,
      }
    : {};
  const body = JSON.stringify({
    id: cartId,
    cart_items: cartItemsToSend,
    ...cleanDates,
    ...cleanDeliveryAmount,
  });
  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(),
  };
  const requestOptions = {
    method: 'PUT',
    headers,
    body,
    mode: 'cors',
  };

  return fetch(
    `${config.apiUrl}/api/cart/validate${
      checkStockDisponibility ? '?checkStockDisponibility=true' : ''
    }`,
    requestOptions
  ).then(handleResponse);
}

function fetchItems(filter = {}, page = 1, limit = 1) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };

  const urlFilter = JSON.stringify(filter);
  return fetch(
    `${config.apiUrl}/api/cart?filter=${urlFilter}&orderByDesc=dateCreate&page=${page}&limit=${limit}`,
    requestOptions
  ).then(handleResponse);
}

function fetchTotalRowNumber(filter) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };
  const urlFilter = JSON.stringify(filter);
  return fetch(`${config.apiUrl}/api/cart/count?filter=${urlFilter}`, requestOptions).then(
    handleResponse
  );
}

function renewCurrentCartWithReferencesExist(itemsByReference) {
  const jsonBody = JSON.stringify(itemsByReference);

  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(),
  };
  const requestOptions = {
    method: 'POST',
    headers,
    body: jsonBody,
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/api/cart/reference_exist`, requestOptions).then(handleResponse);
}

function createCart(
  platformId,
  status = '',
  name = '',
  itemsByReference = null,
  deliveryDates = null,
  comment = null,
  cartType = null
) {
  const references = itemsByReference ? { items_by_reference: itemsByReference } : {};
  const body = {
    status,
    name,
    platform: { id: platformId },
    ...references,
    delivery_date_dry:
      (deliveryDates && getFormatedDate(deliveryDates[catalogConstants.TEMPERATURE_DRY])) || null,
    delivery_date_fresh:
      (deliveryDates && getFormatedDate(deliveryDates[catalogConstants.TEMPERATURE_FRESH])) || null,
    delivery_date_frozen:
      (deliveryDates && getFormatedDate(deliveryDates[catalogConstants.TEMPERATURE_FROZEN])) ||
      null,
    comment,
  };

  if (cartType) {
    body.type = cartType;
  }

  const jsonBody = JSON.stringify(body);

  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(),
  };
  const requestOptions = {
    method: 'POST',
    headers,
    body: jsonBody,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/cart/add`, requestOptions).then(handleResponse);
}

function deleteCart(cartId) {
  const headers = authHeader();
  const requestOptions = {
    method: 'DELETE',
    headers,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/cart/delete/${cartId}`, requestOptions).then(handleResponse);
}

function copyCart(id, newStatus, newName) {
  const body = JSON.stringify({
    status: newStatus,
    name: newName,
  });
  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(),
  };
  const requestOptions = {
    method: 'POST',
    headers,
    body,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/cart/copy/${id}`, requestOptions).then(handleResponse);
}

function updateCart(id, data, withReset = false) {
  const body = JSON.stringify({
    id,
    ...data,
  });
  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(),
  };
  const requestOptions = {
    method: 'PUT',
    headers,
    body,
    mode: 'cors',
  };
  const url =
    withReset === true
      ? `${config.apiUrl}/api/cart/update?reset=true`
      : `${config.apiUrl}/api/cart/update`;
  return fetch(url, requestOptions).then(handleResponse);
}

function downloadCartPdf(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(false),
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/api/cart/pdf/${id}`, requestOptions).then((r) => r.blob());
}
