import React, { useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { catalogService } from 'services';
import { store } from 'helpers';
import ProductCard from 'components/ProductCard';
import FilterTagBar from 'components/FilterTagBarV3/FilterTagBar';
import { selectScreenWidth } from 'selectors/ui';
import { size } from 'components/Core/Utils/Device';
import { aggridConstants } from 'constants/Aggrid.constants';
import { ProductCardWrapper, AgListWrapper } from './AggridListView.style';
import columnDefs from './columnDefinitions';
import localeText from '../locales';
import './AggridListView.scss';

const resizeGrid = (screenWidth, columnApi, gridApi) => {
  if (columnApi && gridApi) {
    columnApi.setColumnVisible('agListNameCell', true);
    columnApi.setColumnVisible('agListReferenceCell', true);
    columnApi.setColumnVisible('agListPriceCell', true);
    columnApi.setColumnVisible('favoriteCell', true);
    columnApi.setColumnVisible('agListQuantityCell', true);
    columnApi.setColumnVisible('tabletCell', false);
    columnApi.setColumnVisible('mobileCell', false);

    if (screenWidth <= size.laptopL) {
      columnApi.setColumnVisible('agListReferenceCell', false);
    }
    if (screenWidth <= size.laptop) {
      columnApi.setColumnVisible('tabletCell', true);
      columnApi.setColumnVisible('agListNameCell', false);
      columnApi.setColumnVisible('agListReferenceCell', false);
      columnApi.setColumnVisible('agListPriceCell', false);
    }
    if (screenWidth <= size.tabletR) {
      columnApi.setColumnVisible('tabletCell', false);
      columnApi.setColumnVisible('agListNameCell', true);
      columnApi.setColumnVisible('agListPriceCell', true);
    }
    if (screenWidth <= size.tablet) {
      columnApi.setColumnVisible('agListPriceCell', false);
    }
    if (screenWidth <= size.mobileL) {
      columnApi.setColumnVisible('mobileCell', true);
      columnApi.setColumnVisible('tabletCell', false);
      columnApi.setColumnVisible('agListNameCell', false);
      columnApi.setColumnVisible('agListPriceCell', false);
      columnApi.setColumnVisible('agListQuantityCell', false);
      columnApi.setColumnVisible('favoriteCell', false);
    }
    gridApi.sizeColumnsToFit();
  }
};

const AggridListView = ({
  rowData,
  onGridReady,
  onFirstDataRendered,
  onCellClicked,
  onFilterChanged,
  frameworkComponents,
  doesExternalFilterPass,
  isExternalFilterPresent,
  handleFilter,
  currentProduct,
  close,
  columnApi,
  gridApi,
  screenWidth,
  rowCount,
  totalRowCount,
  navigateToNextCell,
  searchValue,
}) => {
  useEffect(() => {
    window.addEventListener('resize', resizeGrid(screenWidth, columnApi, gridApi));
  });

  const getRowHeight = () => {
    if (window.innerWidth <= size.mobileL) {
      return aggridConstants.LIST_MOBILE_ROW_HEIGHT;
    }

    return aggridConstants.LIST_ROW_HEIGHT;
  };

  const onColumnResized = (params) => {};

  const onColumnVisible = (params) => {};

  let productsToGetProperties = {};

  const updateCatalogProperties = useCallback((response) => {
    response.forEach((item) => {
      if (gridRef.current !== null) {
        const rowNode = gridRef.current.api.getDisplayedRowAtIndex(item.aggrid_row_index);
        if (rowNode.data !== undefined && rowNode.data.already_get_properties !== true) {
          const rowToUpdate = rowNode.data;
          rowToUpdate.is_favorite = item.is_favorite;
          rowToUpdate.client_stock_quantity = item.client_stock_quantity;

          if (
            item.has_promotion !== undefined &&
            item.has_promotion &&
            item.promotion.display_as_promotion
          ) {
            rowToUpdate.applicable_price = item.applicable_price;
            rowToUpdate.applicable_price_vat = item.applicable_price_vat;
            rowToUpdate.has_promotion = item.has_promotion;

            rowToUpdate.promotion.price = item.promotion.price;
            rowToUpdate.promotion.promo_code = item.promotion.promo_code;
            rowToUpdate.promotion.display_as_promotion = item.promotion.display_as_promotion;
            rowToUpdate.promotion.stock_commitment_remaining =
              item.promotion.stock_commitment_remaining;
            rowToUpdate.promotion.stock_commitment_request =
              item.promotion.stock_commitment_request;
            rowToUpdate.promotion.stock_commitment = item.promotion.stock_commitment;
          }

          rowToUpdate.already_get_properties = true;

          if (item.is_favorite) {
            const row = gridRef.current.api.getDisplayedRowAtIndex(rowNode.rowIndex);
            gridRef.current.api.redrawRows({ rowNodes: [row] });
          }
          rowNode.setDataValue('favoriteCell', rowToUpdate.is_favorite);
          // rowNode.setDataValue('debug_redraw', '.');
          rowNode.updateData(rowToUpdate);
        }
      }
    });

    productsToGetProperties = {};
  });

  const componentProperties = {
    loading: (params) => {
      const state = store.getState();
      if (params.data !== undefined) {
        if (params.data.already_get_properties === true) {
          return null;
        }
      }
      if (params.data !== undefined) {
        productsToGetProperties[params.rowIndex] = {
          id: params.data.id,
          product_id: params.data.product_id,
          aggrid_row_index: params.rowIndex,
        };
      }
      if (
        params.rowIndex === params.node.rowRenderer.lastRenderedRow &&
        Object.keys(productsToGetProperties).length > 0
      ) {
        let productsToGetPropertiesTOsend = Object.values(productsToGetProperties).slice(-20);
        if (
          productsToGetPropertiesTOsend.length === 2 &&
          productsToGetPropertiesTOsend[0].aggrid_row_index === 0 &&
          productsToGetPropertiesTOsend[1].aggrid_row_index < 20
        ) {
          const productsToGetPropertiesNewtoSend = {};
          productsToGetPropertiesNewtoSend[0] = {
            id: productsToGetPropertiesTOsend[0].id,
            product_id: productsToGetPropertiesTOsend[0].product_id,
            aggrid_row_index: productsToGetPropertiesTOsend[0].aggrid_row_index,
          };
          for (let i = 0; i < productsToGetPropertiesTOsend[1].aggrid_row_index; i += 1) {
            const rowNode = gridRef.current.api.getDisplayedRowAtIndex(i + 1);
            productsToGetPropertiesNewtoSend[rowNode.rowIndex] = {
              id: rowNode.data.id,
              product_id: rowNode.data.product_id,
              aggrid_row_index: rowNode.rowIndex,
            };
          }
          productsToGetPropertiesTOsend = productsToGetPropertiesNewtoSend;
        }
        const { platform } = state;
        const filter = {
          platform: platform.selectedId,
        };
        catalogService
          .getCatalogPropertiesV4(filter, JSON.stringify(productsToGetPropertiesTOsend))
          .then(
            (response) => {
              updateCatalogProperties(response);
            },
            (err) => {
              return err;
            }
          );
      }
      return null;
    },
  };

  const rowHeight = getRowHeight();
  const gridRef = useRef(null);

  return (
    <div className="ag-list__container">
      <div className="ag-list__sub-container">
        <AgListWrapper className="ag-list__wrapper">
          <div className="AggridContainer ag-theme-custom-list">
            <FilterTagBar
              handleFilter={handleFilter}
              totalRowCount={totalRowCount}
              rowCount={rowCount}
            />
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              cacheBlockSize={100}
              rowModelType="infinite"
              maxConcurrentDatasourceRequests={2}
              rowSelection="single"
              onGridReady={onGridReady}
              suppressRowClickSelection
              onFilterChanged={onFilterChanged}
              onFirstDataRendered={onFirstDataRendered}
              onCellClicked={onCellClicked}
              localeText={localeText}
              frameworkComponents={frameworkComponents}
              doesExternalFilterPass={doesExternalFilterPass}
              isExternalFilterPresent={isExternalFilterPresent}
              onColumnVisible={onColumnVisible}
              onColumnResized={onColumnResized}
              pivotMode={false}
              suppressContextMenu
              tooltipShowDelay={0}
              components={componentProperties}
              overlayNoRowsTemplate={
                '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">Aucun produit trouvé</span>'
              }
              rowHeight={rowHeight}
              navigateToNextCell={navigateToNextCell}
              handleFilter={handleFilter}
              searchValue={searchValue}
              loadingOverlayComponent="Spinner"
            />
          </div>
        </AgListWrapper>
        {screenWidth > size.tabletR && (
          <div className="ag-list__product-card-container">
            <div className="ag-list__product-card-container__border">
              {currentProduct && (
                <ProductCardWrapper className="ag-list__product-card-wrapper">
                  <ProductCard
                    product={currentProduct}
                    close={close}
                    handleFilter={handleFilter}
                    searchValue={searchValue}
                    source="aggridListView"
                  />
                </ProductCardWrapper>
              )}
              {!currentProduct && (
                <div className="product-card__no-product">Aucun article séléctionné</div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  screenWidth: selectScreenWidth(state),
});

AggridListView.propTypes = {
  rowData: PropTypes.array,
  onGridReady: PropTypes.func,
  onFirstDataRendered: PropTypes.func,
  onFilterChanged: PropTypes.func,
  frameworkComponents: PropTypes.object,
  doesExternalFilterPass: PropTypes.func,
  isExternalFilterPresent: PropTypes.func,
  handleFilter: PropTypes.func,
  currentProduct: PropTypes.object,
  close: PropTypes.func,
  gridApi: PropTypes.object,
  screenWidth: PropTypes.number,
  rowCount: PropTypes.number,
  navigateToNextCell: PropTypes.func,
  searchValue: PropTypes.string,
};

export default connect(mapStateToProps)(AggridListView);
