import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProductCategoryControl from 'components/CatalogControlsV3/ProductCategoryControl';
import AvailabilityControl from 'components/CatalogControlsV3/AvailabilityControl';
import TemperatureControl from 'components/CatalogControlsV3/TemperatureControl';
import FavoriteControl from 'components/CatalogControlsV3/FavoriteControl';
import NewControl from 'components/CatalogControlsV3/NewControl';
import PromotionControl from 'components/CatalogControlsV3/PromotionControl';
import TopSalesControl from 'components/CatalogControlsV3/TopSalesControl';
import TabControl from 'components/CatalogControlsV3/TabControl';
import { BiListUl } from '@react-icons/all-files/bi/BiListUl';
import { BiTable } from '@react-icons/all-files/bi/BiTable';
import { aggridFilterActions } from 'actions/AggridFilter';
import { aggridSearchBarActions } from 'actions/AggridSearchBar';
import { selectAggridFilter } from 'selectors/aggridFilter';
import { selectScreenWidth } from 'selectors/ui';
import { selectAggridSearchValue } from 'selectors/search';
import { size } from 'components/Core/Utils/Device';
import AggridSearchBar from 'components/Core/SearchBarV3/AggridSearchBar';
import { getAvailableTemperatures } from 'helpers/Platform';
import { store } from 'helpers';
import { aggridFilterConstants } from '../../constants';
import { CatalogControlsWrapper } from './CatalogControls.style';
import './CatalogControls.scss';

const {
  FILTER_FAVORITE,
  FILTER_PROMOTION,
  FILTER_NEW,
  FILTER_TOP_SALES,
  FILTER_AVAILABLE,
  FILTER_UNAVAILABLE,
  FILTER_PREORDER,
  FILTER_STORE_AVAILABLE,
  FILTER_STORE_UNAVAILABLE,
  FILTER_STORE_NO_ASSORTMENT,
} = aggridFilterConstants;

class CatalogControls extends Component {
  availableStoreAvailabilityFilters = [
    {
      title: 'En stock',
      value: FILTER_STORE_AVAILABLE,
    },
    {
      title: 'Indisponible',
      value: FILTER_STORE_UNAVAILABLE,
    },
    {
      title: 'Hors assortiment',
      value: FILTER_STORE_NO_ASSORTMENT,
    },
  ];

  constructor(props) {
    super(props);
    this.handleQuickFilterClick = this.handleQuickFilterClick.bind(this);
    this.handleQuickListClick = this.handleQuickListClick.bind(this);
  }

  getAvailableAvailabilities(rowData) {
    const availableAvailabilityFilters = [
      {
        title: 'En stock',
        value: FILTER_AVAILABLE,
      },
      {
        title: 'Indisponible',
        value: FILTER_UNAVAILABLE,
      },
    ];

    if (this.hasPreorder()) {
      availableAvailabilityFilters.push({ title: 'Précommande', value: FILTER_PREORDER });
    }

    return availableAvailabilityFilters;
  }

  hasPreorder() {
    const state = store.getState();
    const { platform, user } = state;
    if (typeof user === 'undefined' || typeof user.information.client === 'undefined') return false;
    const statusPreorder = user.information.client.client_status.filter(
      (item) => item.platform_id === platform.selectedId
    )[0].status_preorder;
    if (statusPreorder === 'ACTIVE') {
      return true;
    }
    return false;
  }

  handleQuickFilterClick(filterValue) {
    const { currentFilters, setCurrentFilters, handleFilter } = this.props;
    const quickFilters = currentFilters.quickList || [];

    let newQuickFilters = [...quickFilters, filterValue];
    if (quickFilters.includes(filterValue)) {
      newQuickFilters = newQuickFilters.filter((item) => item !== filterValue);
    }
    const newActiveFilters = { ...currentFilters, quickList: newQuickFilters };
    setCurrentFilters(newActiveFilters);
    handleFilter(newActiveFilters);
  }

  handleQuickListClick(filterValue) {
    const {
      currentFilters,
      setCurrentFilters,
      handleFilter,
      searchValue,
      setSearchValue,
    } = this.props;
    const quickFilters = currentFilters.quickList || [];

    let newQuickFilters = [...quickFilters, filterValue];
    if (quickFilters.includes(filterValue)) {
      newQuickFilters = [];
      // newQuickFilters = newQuickFilters.filter((item) => item !== filterValue);
    } else {
      newQuickFilters = [];
      newQuickFilters.push(filterValue);
    }

    let newActiveFilters = {};
    if (filterValue === aggridFilterConstants.FILTER_NEW) {
      newQuickFilters = [];
      newQuickFilters.push(filterValue);
      newActiveFilters = {
        ...currentFilters,
        quickList: newQuickFilters,
      };
    } else {
      newActiveFilters = {
        ...currentFilters,
        quickList: newQuickFilters,
        availabilityList: [],
        categoryColumns: null,
        category: null,
        selectedCategoryList: [],
        storeAvailabilityList: [],
        temperatureList: [],
        categoryId: null,
        selection: null,
        searchValue: null,
        isPreorder: null,
      };

      if (searchValue && searchValue !== null) {
        setSearchValue('', handleFilter);
      }
    }
    setCurrentFilters(newActiveFilters);
    handleFilter(newActiveFilters);
  }

  render() {
    const {
      rowData,
      selectedPlatform,
      handleFilter,
      setCurrentFilters,
      currentFilters,
      setSearchValue,
      viewType,
      toggleView,
      screenWidth,
      counters,
      counters2,
    } = this.props;
    const newProducts = rowData.filter((item) => item.is_new);
    const promotionProducts = rowData.filter((item) => item.has_promotion);
    const quickFilterList = currentFilters.quickList || [];
    const availableAvailabilityFilters = this.getAvailableAvailabilities(rowData) || [];

    return (
      <CatalogControlsWrapper className="catalog-controls__controls-container" viewType={viewType}>
        {screenWidth > size.tablet && (
          <>
            <TabControl>
              <ProductCategoryControl
                handleClickFilter={handleFilter}
                setCurrentFilters={setCurrentFilters}
                currentFilters={currentFilters}
              />
            </TabControl>
            <TabControl>
              <AvailabilityControl
                handleClickFilter={handleFilter}
                setCurrentFilters={setCurrentFilters}
                currentFilters={currentFilters}
                list={availableAvailabilityFilters}
                storeList={this.availableStoreAvailabilityFilters}
              />
            </TabControl>
            <TabControl>
              <TemperatureControl
                handleClickFilter={handleFilter}
                setCurrentFilters={setCurrentFilters}
                currentFilters={currentFilters}
                list={getAvailableTemperatures(selectedPlatform)}
              />
            </TabControl>
            <TabControl
              handleTabClick={this.handleQuickListClick}
              handleTabParameter={FILTER_NEW}
              isActive={quickFilterList.includes(FILTER_NEW)}
            >
              <NewControl counters={counters} notifCounterColor="red" count={newProducts.length} />
            </TabControl>
            <div className="catalog-controls__v-separator" />
            <TabControl
              handleTabClick={this.handleQuickListClick}
              handleTabParameter={FILTER_PROMOTION}
              isActive={quickFilterList.includes(FILTER_PROMOTION)}
            >
              <PromotionControl counters={counters} count={promotionProducts.length} />
            </TabControl>
            <TabControl
              handleTabClick={this.handleQuickListClick}
              handleTabParameter={FILTER_FAVORITE}
              isActive={quickFilterList.includes(FILTER_FAVORITE)}
            >
              <FavoriteControl counters={counters2} notifCounterColor="#01366B" />
            </TabControl>
            <TabControl
              handleTabClick={this.handleQuickListClick}
              handleTabParameter={FILTER_TOP_SALES}
              isActive={quickFilterList.includes(FILTER_TOP_SALES)}
            >
              <TopSalesControl />
            </TabControl>
            <div className="catalog-controls__v-separator" />
          </>
        )}
        <div className="catalog-controls__searchbar">
          <AggridSearchBar
            handleClickFilter={handleFilter}
            currentFilters={currentFilters}
            setCurrentFilters={setCurrentFilters}
          />
        </div>
        {screenWidth > size.tablet && (
          <div className="catalog-controls__view-switch" onClick={toggleView}>
            {viewType && (
              <>
                <BiListUl />
                <span>Vue liste</span>
              </>
            )}
            {!viewType && (
              <>
                <BiTable />
                <span>Vue tableau</span>
              </>
            )}
          </div>
        )}
      </CatalogControlsWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentFilters: (filters) => dispatch(aggridFilterActions.setCurrentFilters(filters)),
  setSearchValue: (value, handleFilter) => {
    dispatch(aggridSearchBarActions.setSearchBar(value));
    handleFilter('');
  },
  toggleView: () => dispatch(aggridFilterActions.toggleAggridView()),
});

const mapStateToProps = (state) => ({
  currentFilters: selectAggridFilter(state),
  searchValue: selectAggridSearchValue(state),
  screenWidth: selectScreenWidth(state),
});

CatalogControls.propTypes = {
  selectedPlatform: PropTypes.shape({
    attributs: PropTypes.arrayOf(PropTypes.object),
  }),
  handleFilter: PropTypes.func,
  rowData: PropTypes.arrayOf(
    PropTypes.shape({
      is_new: PropTypes.bool,
    })
  ),
  aggridFilter: PropTypes.shape({
    quickList: PropTypes.arrayOf(PropTypes.string),
    temperatureList: PropTypes.arrayOf(PropTypes.string),
  }),
  viewType: PropTypes.bool,
  setSearchValue: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogControls);
