import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import pull from 'lodash.pull';
import { aggridSearchBarActions } from 'actions/AggridSearchBar';
import { searchActions } from 'actions/Search';
import { aggridFilterConstants } from 'constants/AggridFilter.constants';
import { aggridFilterActions } from 'actions/AggridFilter';
import { selectAggridFilter } from 'selectors/aggridFilter';
import Icon from 'components/Icon/Icon';
import { IoMdClose } from '@react-icons/all-files/io/IoMdClose';

const SearchBar = (props) => (
  <>
    <span>
      <Icon content={'\\e93e'} />
    </span>
    <div className="aggrid-searchbar__wrapper">
      <input
        id="inputSearch"
        type="text"
        placeholder="Rechercher un produit"
        onChange={(e) =>
          props.onChange(e, props.handleClickFilter, props.currentFilters, props.setCurrentFilters)
        }
        onBlur={(e) => props.onBlur(e, props.infosLogClient)}
        value={props.searchValue === null ? '' : props.searchValue}
        className="search-box"
      />
      {props.searchValue && (
        <span>
          <IoMdClose
            fontSize="18px"
            id="clearSearch"
            onClick={(e) => props.onClearInput(e, props.handleClickFilter)}
          />
        </span>
      )}
    </div>
  </>
);

const mapStateToProps = (state) => {
  const {
    aggridSearchBar,
    user,
    platform: { selectedId: platformId },
    handleChange,
  } = state;
  const {
    information: { id, type, username },
  } = user;
  const infosLog = {
    id: user && id,
    username,
    type: user && type,
    platformId,
  };

  return {
    searchValue: aggridSearchBar && aggridSearchBar.searchValue,
    infosLogClient: infosLog,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // onChange: (event) => dispatch(aggridSearchBarActions.searchForProduct(event.currentTarget.value)),
  onChange: (event, handleClickFilter, currentFilters, setCurrentFilters) => {
    const valSearch = event.currentTarget.value;
    dispatch(aggridSearchBarActions.searchForProduct(valSearch));
    if (valSearch.length === 0 || valSearch.length > 2) {
      let newActiveFilters = {};
      const newQuickList = pull(
        currentFilters.quickList,
        aggridFilterConstants.FILTER_FAVORITE,
        aggridFilterConstants.FILTER_PROMOTION,
        aggridFilterConstants.FILTER_TOP_SALES
      );
      newActiveFilters = {
        ...currentFilters,
        quickList: newQuickList,
      };
      setCurrentFilters(newActiveFilters);
      handleClickFilter(event.currentTarget.value);
    }
  },
  onBlur: (event, obj) => {
    const valSearch = event.currentTarget.value;
    const objLog = {
      search_value: valSearch,
      username: obj.username,
      type: obj.type,
      platform: { id: obj.platformId },
    };
    if (valSearch.length > 3) {
      dispatch(searchActions.searchLogs(objLog));
    }
  },
  onClearInput: (event, handleClickFilter) => {
    const elem = document.getElementById('inputSearch');
    elem.value = '';
    // dispatch(aggridSearchBarActions.searchForProduct(elem.value));
    dispatch(aggridSearchBarActions.setSearchBar(''));
    handleClickFilter('');
  },
});

SearchBar.propTypes = {
  searchValue: PropTypes.string,
  infosLogClient: PropTypes.object,
  handleClickFilter: PropTypes.func,
  currentFilters: PropTypes.object,
  setCurrentFilters: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
