import React from 'react';
import PropTypes from 'prop-types';
import { ClientStatus, ClientButtons } from './Clients';
import {
  ProductImage,
  ProductWording,
  ProductQuantity,
  ProductFavorite,
  ProductQuantityText,
  ProductTextBloc,
  ProductTotal,
  ProductActionDelete,
} from './Products';
import { MessageActions } from './Messages';
import { CommercialsActions } from './Commercials';
import { TermsActions } from './Terms';
import { CommandeValidateIcon } from './ValidateOrder';
import { AssortmentActions } from './Assortment';
import { CreditAction } from './Credit';
import MessageClient from './Messages/MessageClient';

const CellTypesMap = {
  PRODUCT_IMAGE: ProductImage,
  PRODUCT_WORDING: ProductWording,
  PRODUCT_FAVORITE: ProductFavorite,
  PRODUCT_QUANTITY: ProductQuantity,
  PRODUCT_QUANTITY_TEXT: ProductQuantityText,
  PRODUCT_TEXT_BLOC: ProductTextBloc,
  PRODUCT_PRICE_TOTAL: ProductTotal,
  PRODUCT_ICON_BLOC: CommandeValidateIcon,
  PRODUCT_ACTION_DELETE: ProductActionDelete,
  MESSAGE_ACTIONS: MessageActions,
  MESSAGE_CLIENT: MessageClient,
  COMMERCIAL_ACTIONS: CommercialsActions,
  CLIENT_STATUS: ClientStatus,
  CLIENT_BUTTONS: ClientButtons,
  TERMS_ACTIONS: TermsActions,
  ASSORTMENT_ACTIONS: AssortmentActions,
  CREDIT_ACTION: CreditAction,
};

const Cell = ({ type, ...props }) => {
  const CellType = CellTypesMap[type];
  if (CellType) {
    return <CellType {...props} />;
  }

  return <td />;
};

Cell.propTypes = {
  type: PropTypes.string,
};

export default Cell;
