/* eslint-disable prefer-destructuring */
/* eslint-disable no-self-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import { tableTypes } from 'components/Table/tableTypes';
import get from 'lodash.get';
import { numberToPrice, dateTimeToFormatedDate, generatePcbTextBlocks } from '.';

const IMAGE_PLACEHOLDER = 'https://via.placeholder.com/150';
const TEMPERATURES = {
  SEC: 1,
  POSITIF: 2,
  FROID: 3,
};
const {
  TABLE_TYPE_CATALOG,
  TABLE_TYPE_SEARCH,
  TABLE_TYPE_SEARCH_CLIENT,
  TABLE_TYPE_CART,
  TABLE_TYPE_CART_ITEMS,
  TABLE_TYPE_ORDER,
  TABLE_TYPE_CLIENT,
  TABLE_TYPE_ORDER_ITEMS,
  TABLE_TYPE_INVOICE_ITEMS,
  TABLE_TYPE_TERMS,
  TABLE_TYPE_COMMERCIAL,
  TABLE_TYPE_MESSAGE,
  TABLE_TYPE_ASSORTMENT,
  TABLE_TYPE_CREDIT_ITEMS,
} = tableTypes;

function getCells(item, columns, dataLinks, initialItem) {
  const cells = [];
  const datas = {};
  let textBlocs = [];

  const itemKeys = Object.keys(item || {});
  itemKeys.forEach((itemKey) => {
    const realKey = (dataLinks && dataLinks[itemKey]) || itemKey;
    datas[realKey] = item[itemKey];
    const column = columns.find((col) => col.dataType === realKey);
    if (column && column.dataFormat === 'date') {
      datas[itemKey] = dateTimeToFormatedDate(datas[itemKey]);
    }
    if (column && column.dataFormat === 'price') {
      datas[itemKey] = numberToPrice(datas[itemKey]);
    }
  });
  columns.forEach((column) => {
    const { type, dataType } = column;
    switch (type) {
      case 'PRODUCT_IMAGE':
        cells.push({
          urlPath: (datas.images && datas.images.small) || IMAGE_PLACEHOLDER,
          isProductNew: datas.is_new,
          hasProductPromotion: datas.has_promotion,
          displayAsPromotion: datas.display_as_promotion,
        });
        break;
      case 'PRODUCT_WORDING':
        cells.push({
          isProductNew: datas.is_new,
          hasProductPromotion: datas.has_promotion,
          displayAsPromotion: datas.display_as_promotion,
          productName: datas.name,
          productRef: datas.reference || datas.item_reference,
          productEan: datas.ean13,
          productStock:
            datas.stock ||
            (datas.item_stock && {
              value_packing: datas.item_stock.packages,
              value_cu: datas.item_stock.items,
            }),
          productTemperature: TEMPERATURES[datas.type],
          isProductPreordered: datas.is_preorder || datas.item_is_preorder,
        });
        break;
      case 'CLIENT_STATUS':
        cells.push({
          clientStatus: datas.client_status,
          isPreorder: column.dataType !== 'status_catalog',
        });
        break;
      case 'CLIENT_BUTTONS':
        cells.push({
          clientStatus: datas.client_status,
        });
        break;
      case 'PRODUCT_QUANTITY':
        cells.push({
          quantity: datas.quantity || 0,
        });
        break;
      case 'PRODUCT_PRICE_TOTAL':
        cells.push({
          shouldRerender: true,
          textBlocs: [
            {
              content: `${numberToPrice(datas.final_price)}` || '0.00 €',
              isBold: true,
            },
          ],
          float: 'right',
        });
        break;
      case 'PRODUCT_ICON_BLOC':
        cells.push({
          order: initialItem,
        });
        break;
      case 'PRODUCT_ACTION_DELETE':
        cells.push({
          cartItemId: initialItem.id,
          status: get(initialItem, 'cart.status', ''),
        });
        break;
      case 'TERMS_ACTIONS':
        cells.push({
          term: initialItem,
        });
        break;
      case 'CREDIT_ACTION':
        cells.push({
          creditId: initialItem.id,
        });
        break;
      case 'MESSAGE_ACTIONS':
        cells.push({
          message: initialItem,
        });
        break;
      case 'ASSORTMENT_ACTIONS':
        cells.push({
          assortment: initialItem,
        });
        break;
      case 'COMMERCIAL_ACTIONS':
        cells.push({
          commercial: initialItem,
        });
        break;
      default:
        switch (dataType) {
          case 'invoice_number':
            cells.push({
              textBlocs: [
                {
                  content: datas.invoice_item.invoice_number,
                  isBoldGray: true,
                },
              ],
            });
            break;
          case 'item_ean13':
            cells.push({
              textBlocs: [
                {
                  content: datas.invoice_item.ean13,
                  isBoldGray: true,
                },
              ],
            });
            break;
          case 'invoice_item_reference':
            cells.push({
              textBlocs: [
                {
                  content: datas.invoice_item.reference,
                  isBoldGray: true,
                },
              ],
            });
            break;
          case 'client_informations':
            cells.push({
              textBlocs: [
                {
                  content: `${datas.ext_code.slice(1)}`,
                  isBoldGray: true,
                },
                {
                  content: `${datas.name}`,
                  isBoldGray: true,
                },
              ],
            });
            break;
          case 'assortment_reference':
            cells.push({
              textBlocs: [
                {
                  content: `${datas.label || '-'}`,
                  isBoldGray: true,
                },
              ],
            });
            break;
          case 'assortment_name':
            cells.push({
              textBlocs: [
                {
                  content: `${datas.name}`,
                  isSmall: true,
                },
              ],
            });
            break;
          case 'ext_code':
            cells.push({
              textBlocs: [
                {
                  content: `${datas.ext_code.slice(1)}`,
                },
              ],
            });
            break;
          case 'brand.name':
            cells.push({
              textBlocs: [
                {
                  content: `${datas.brand && datas.brand.name}`,
                  isBoldGray: true,
                },
              ],
            });
            break;
          case 'user.client_name':
            cells.push({
              textBlocs: [
                {
                  content: datas.user.client_ext_code
                    ? `${datas.user.client_ext_code.slice(1)}`
                    : '',
                  isBoldGray: true,
                },
                {
                  content: `${datas.user.client_name}`,
                  isBoldGray: true,
                },
              ],
            });
            break;
          case 'manufacturer':
            cells.push({
              text: datas.manufacturer ? datas.manufacturer.name : '',
            });
            break;
          case 'pcb':
            cells.push({
              textBlocs: [...generatePcbTextBlocks(datas.item ? datas.item : datas)],
            });
            break;
          case 'price':
            textBlocs = [];
            if (!datas.has_promotion) {
              textBlocs.push({
                content: `${numberToPrice(datas.item ? datas.item.price : datas.price)} HT ${
                  (datas.item && datas.item.variable_weight) || datas.variable_weight
                    ? 'le kilo'
                    : "l'unité"
                }`,
                isBold: true,
              });
              textBlocs.push({
                content: `${numberToPrice(
                  Number(datas.item ? datas.item.price : datas.price) *
                    Number(datas.item ? datas.item.pcb : datas.pcb)
                )} par colis`,
                isSmall: true,
              });
            } else if (datas.has_promotion) {
              textBlocs.push({
                content: `${numberToPrice(
                  Number(datas.promotion ? datas.promotion.price : datas.item_price)
                )}HT ${datas.variable_weight ? 'le kilo' : "l'unité"}`,
                isBold: true,
              });
              if (datas.display_as_promotion) {
                textBlocs.push({
                  content: `${numberToPrice(datas.price || datas.item_price)} HT ${
                    datas.variable_weight ? 'le kilo' : "l'unité"
                  }`,
                  isSmall: true,
                  isDeleted: true,
                });
              }
              textBlocs.push({
                content: `${numberToPrice(
                  Number(datas.promotion ? datas.promotion.price : datas.item_price) * datas.pcb
                )} par colis`,
                isSmall: true,
              });
            }
            cells.push({ textBlocs });
            break;
          case 'price_vat':
            textBlocs = [];
            if (!datas.has_promotion) {
              textBlocs.push({
                content: `${numberToPrice(datas.price_vat)} TTC l'unité`,
                isBold: true,
              });
              textBlocs.push({
                content: `${numberToPrice(Number(datas.price_vat) * Number(datas.pcb))} par colis`,
                isSmall: true,
              });
            } else if (datas.has_promotion) {
              textBlocs.push({
                content: `${numberToPrice(Number(datas.promotion.price))}TTC l'unité`,
                isBold: true,
              });
              if (datas.display_as_promotion) {
                textBlocs.push({
                  content: `${numberToPrice(datas.price_vat)} TTC l'unité`,
                  isSmall: true,
                  isDeleted: true,
                });
              }
              textBlocs.push({
                content: `${numberToPrice(Number(datas.promotion.price) * datas.pcb)} par colis`,
                isSmall: true,
              });
            }
            cells.push({ textBlocs });
            break;
          case 'order_references':
            textBlocs = [];
            textBlocs.push({
              content: `${Object.keys(datas.count_items_by_type).reduce(
                (prev, current) => prev + datas.count_items_by_type[current],
                0
              )} Réf.`,
              isBold: true,
            });
            textBlocs.push({
              content: `${datas.references.packages} colis`,
              isBold: true,
            });
            cells.push({ textBlocs });
            break;
          case 'quantity_text':
            cells.push({ text: datas.quantity || 0 });
            break;
          case 'temperature':
            if (datas.totals_by_type && Object.keys(datas.totals_by_type).length) {
              textBlocs = Object.keys(datas.totals_by_type).map((key) => ({
                iconType: key,
                content: ` ${key}`,
                isBold: true,
              }));
              cells.push({ textBlocs });
            } else {
              cells.push({ text: '-' });
            }
            break;
          case 'totals_by_type':
            if (datas.count_items_by_type && Object.keys(datas.count_items_by_type).length) {
              textBlocs = Object.keys(datas.count_items_by_type).map((key) => ({
                iconType: key,
                content: ` ${key} : ${datas.count_items_by_type[key]} Réf.`,
                isBold: true,
              }));
              cells.push({ textBlocs });
            } else {
              cells.push({ text: '-' });
            }
            break;
          case 'is_preorder':
            cells.push({
              text: datas.is_preorder === true ? 'Catalogue précommande' : 'Catalogue permanent',
              style: column.style || {},
            });
            break;
          case 'order.id':
            cells.push({
              text: datas.id,
              style: column.style || {},
            });
            break;
          case 'order_manufacturer':
            cells.push({
              text: datas['manufacturer.name'] || '',
            });
            break;
          case 'commercial_informations':
            cells.push({
              textBlocs: [
                {
                  content: `${datas.id}`,
                  isBoldGray: true,
                },
                datas.firstname || datas.lastname
                  ? {
                      content: [datas.firstname, datas.lastname].join(' ').trim(),
                      isBoldGray: true,
                    }
                  : {
                      content: `${datas.username || ''}`,
                      isBoldGray: true,
                    },
              ],
            });
            break;
          case 'total_price':
            cells.push({
              textBlocs: [
                {
                  content: `${numberToPrice(Number(datas.total_price))} HT`,
                  isBold: true,
                },
              ],
            });
            break;
          case 'credit_order_wording':
            cells.push({
              textBlocs: [
                {
                  content: datas.invoice_item.label,
                  isBoldGray: true,
                },
                {
                  content: `Réf: ${
                    datas.invoice_item ? datas.invoice_item.reference : datas.reference
                  } - 
                      EAN ${datas.invoice_item.ean13}`,
                  isSmall: true,
                },
              ],
            });
            break;
          case 'invoice_wording':
            cells.push({
              textBlocs: [
                {
                  content: datas.item ? datas.item.name : datas.name || '',
                  isBoldGray: true,
                },
                {
                  content: `Réf: ${
                    datas.item ? datas.item.reference : datas.reference || ''
                  } - EAN ${datas.ean13}`,
                  isSmall: true,
                },
              ],
            });
            break;
          case 'invoice_manufacturer':
            cells.push({
              text: datas.item && datas.item.manufacturer ? datas.item.manufacturer.name : '',
            });
            break;
          case 'credit_order_manufacturer':
            cells.push({
              textBlocs: [
                {
                  content:
                    datas.invoice_item && datas.invoice_item.manufacturer_name
                      ? datas.invoice_item.manufacturer_name
                      : 'NON DETERMINE',
                  isBold: true,
                },
              ],
            });
            break;
          case 'credit_order_pcb':
            cells.push({
              textBlocs: [
                {
                  content: `${datas.invoice_item.pcb} unité`,
                  isBold: true,
                },
                {
                  content: 'par colis',
                  isSmall: true,
                },
              ],
            });
            break;
          case 'credit_order_price':
            cells.push({
              textBlocs: [
                {
                  content: `${datas.invoice_item.price} € TTC l'unité`,
                  isBold: true,
                },
                {
                  content: `${numberToPrice(
                    Number(datas.invoice_item.price) * Number(datas.invoice_item.pcb)
                  )} par colis`,
                  isSmall: true,
                },
              ],
            });
            break;
          case 'credit_order_quantity':
            cells.push({
              textBlocs: [
                {
                  content: datas.quantity,
                  isBoldGray: true,
                },
              ],
            });
            break;
          case 'credit_order_final_price':
            cells.push({
              textBlocs: [
                {
                  content: `${numberToPrice(datas.invoice_item.final_price)} HT`,
                  isBold: true,
                },
                {
                  content: `${numberToPrice(datas.invoice_item.final_price_vat)} TTC`,
                  isBold: true,
                },
              ],
            });
            break;
          case 'credit_invoice_quantity':
            cells.push({
              textBlocs: [
                {
                  content: datas.quantity,
                  isBoldGray: true,
                },
              ],
            });
            break;
          case 'credit_invoice_final_price':
            cells.push({
              textBlocs: [
                {
                  content: `${numberToPrice(datas.final_price)} HT`,
                  isBold: true,
                },
                {
                  content: `${numberToPrice(datas.final_price_vat)} TTC`,
                  isBold: true,
                },
              ],
            });
            break;
          case 'quantity_invoiced_package':
            cells.push({
              textBlocs: [
                {
                  content: datas.quantity_invoiced_package,
                },
              ],
            });
            break;
          default:
            if (datas[dataType]) {
              cells.push({
                text: datas[dataType],
                style: column.style || {},
              });
            } else if (item[dataType]) {
              cells.push({
                text: item[dataType],
                style: column.style || {},
              });
            } else {
              cells.push({
                text: '-',
              });
            }
        }
      // code block
    }
  });
  return cells;
}

export const parseItems = (items, columns, dataLinks) => {
  const rows = [];

  items.forEach((item) => {
    const initialItem = item;
    if (item.item && item.quantity_invoiced_u_c === undefined) {
      item = Object.assign(item.item, {
        final_price: item.final_price,
        final_price_vat: item.final_price_vat,
        quantity_invoiced_package: item.quantity_invoiced_package,
      });
    }

    const cells = getCells(item, columns, dataLinks, initialItem);
    const data = {
      product: item,
      // item_id is used in order items, regular ids are the orderItem entity ids
      productId: item.item_id || item.id,
      productPrice: item.price,
      username: item.customers && item.customers.length > 0 ? item.customers[0].username : null,
    };
    if ((item.categories && item.categories.length > 0) || item.item_category_name) {
      // item.item_category_name comes from order items
      data.category =
        item.categories && item.categories.length > 0
          ? item.categories[0].name
          : item.item_category_name;
    }
    rows.push({
      cells,
      data,
    });
  });
  return rows;
};

export const getElementsNameByType = (type) => {
  switch (type) {
    case TABLE_TYPE_CATALOG:
      return 'produit(s)';
    case TABLE_TYPE_SEARCH:
      return 'produit(s)';
    case TABLE_TYPE_SEARCH_CLIENT:
      return 'clients';
    case TABLE_TYPE_CART:
      return 'paniers';
    case TABLE_TYPE_CART_ITEMS:
      return 'produit(s)';
    case TABLE_TYPE_ORDER:
      return 'commandes';
    case TABLE_TYPE_CLIENT:
      return 'clients';
    case TABLE_TYPE_ORDER_ITEMS || TABLE_TYPE_INVOICE_ITEMS:
      return 'produits';
    case TABLE_TYPE_TERMS:
      return 'CGV';
    case TABLE_TYPE_COMMERCIAL:
      return 'commerciaux';
    default:
      return 'éléments';
  }
};

export const getTableStateByType = (type, state) => {
  const {
    catalog,
    search,
    cart,
    cartItems,
    order,
    orderItems,
    invoiceItems,
    client,
    message,
    terms,
    commercial,
    assortment,
    credit,
  } = state;

  switch (type) {
    case TABLE_TYPE_CATALOG:
      return catalog;
    case TABLE_TYPE_SEARCH:
      return search;
    case TABLE_TYPE_SEARCH_CLIENT:
      return search;
    case TABLE_TYPE_CART:
      return cart;
    case TABLE_TYPE_CART_ITEMS:
      return cartItems;
    case TABLE_TYPE_ORDER:
      return order;
    case TABLE_TYPE_ORDER_ITEMS:
      return orderItems;
    case TABLE_TYPE_INVOICE_ITEMS:
      return invoiceItems;
    case TABLE_TYPE_CLIENT:
      return client;
    case TABLE_TYPE_MESSAGE:
      return message;
    case TABLE_TYPE_TERMS:
      return terms;
    case TABLE_TYPE_COMMERCIAL:
      return commercial;
    case TABLE_TYPE_ASSORTMENT:
      return assortment;
    case TABLE_TYPE_CREDIT_ITEMS:
      return credit;
    default:
      return {};
  }
};

export const getTableCollectionsByType = (type, state) => {
  const currentState = getTableStateByType(type, state);

  if (type === TABLE_TYPE_SEARCH_CLIENT) {
    return {
      collections: currentState.collectionsClient,
      collectionsCount: currentState.collectionsClientCount,
    };
  }

  if (currentState && currentState.collections && currentState.collectionsCount) {
    return {
      collections: currentState.collections,
      collectionsCount: currentState.collectionsCount,
    };
  }

  return null;
};
