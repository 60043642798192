import styled from 'styled-components';
import screen from 'components/Core/Utils/Device';

export default (Component) => styled(Component)`
.button-add-elements {  
  text-align: center;
  width: 100%;
  a {
    height: 40px;
    width: 230px;
    padding: 10px;
    margin: 5px auto;
  }
  .previous {
    padding-top: 10px;
  }
  .next {
    padding-bottom: 10px;
  }
}

.information-percentage {
  margin: 10px auto;
  color: #4A4A4A;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 15px;
  text-align: center;
  
  .percentage {
    width: 220px;
    height: 12px;
    margin: 10px auto;
    border-bottom: 1px solid #01366B;
    div {
      height: 12px;
      background-color: #01366B;
    }
  }
}

.table-wrapper {
  background: #fff;
  .table {
    border-spacing: 0px;
    padding-top: 10px;
    width: 100%;
  }

  .table tr, .table td {
    vertical-align: middle;
    &.row {
      &.unclickable {
        cursor: not-allowed;
        opacity: 0.6;
      }
    }
  }

  .table thead {
    padding-left: 20px;
    padding-right: 20px;
    height: 32px;
  }

  .table thead tr {
    height: 32px;
    background-color: #fff;
  }

  .table thead th {
    border-bottom: 5px solid #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 500;
  }

  .table tbody tr {
    background: white;
    cursor: pointer;
  }

  .table tbody td, .table tbody th {
    /* padding-top: 10px;
    padding-bottom: 10px; */
    border-bottom: 5px solid #fff;
  }

  

  .table tbody th {
    text-align: left;
    background-color: #01366b;
    color: #fff;
    padding-left: 20px;
    font-weight: normal;
    text-transform: capitalize;
  }

  .table tbody {
    width: 100vh;
    .group-row {
      th {
        padding: 5px 20px;
      }
    }
  }
  .table__message {
    text-align: center;
  }

  /*---------------------------------------------- th table -----------------------------------------------------------------*/
  .header {
    color:#000;
    text-transform: uppercase;
    text-align: left;
  }

  .header:first-child {
    padding-left: 20px;
  }

  /* -------------------------------------td table width------------------------------------------------------------------------ */

  .header_product-image {
    width: 105px;
    padding-left:20px;
    padding-right: 10px;
  }

  .header_order-number {
    width: 120px;
    cursor: pointer;
  }

  .header_order-temperature {
    width: 120px;
  }

  .header_product-date{
    width: 140px;
    padding-left:20px;
  }

  .header_product-wording{
    width: 300px;
    padding: 5px 5px 5px 20px;
  }

  .header_product-action{
    width: 48px;
  }

  .header_product-brand{
    width: 150px;
    padding: 0 10px 0 5px;
  }

  .header_product-wording_credit{
    width: 300px;
    padding-left: 20px;
    padding-top: 12px;
    .small {
      margin: 8px 0px;
      font-size: 14px;
    }
  }

  .header_product-pcb{
    width: 140px;
    padding: 5px;
    .small {
      letter-spacing: 0.15px;
    }
  }

  .header_product-price{
    width: 210px;
    padding: 5px;
    .small {
      letter-spacing: 0.15px;
    }
  }

  .header_product-favorite{
    width: 40px;
  }

  .header_product-quantity{
    width: 156px;
    &>div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .input-quantite-cadre {
      height: 25px;
    }
  }

  .header_product-total{
    width: 96px;
  }

  .header_product-default{
    width: 200px;
  }

  .cart__section-temperature{
    padding:15px;
  }

  .permanent-cart__ref {
    color: #01366B;
    font-family: Montserrat;
    font-weight: bold;
    line-height: 35px;
    margin:50px;
  }

  .header_message-wording {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-align: justify;
    padding-right: 20px;
  }

  .header_message-action {
    width: 140px;
  }

  /*----------------------------------------------responsive-----------------------------------------------------*/
  @media all and ${screen.tablet} {
    table {
      position: relative;
    }
    // hide the headers
    thead {
      display: none;
    }
    .table tbody {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100vw;
      margin-bottom: 10px;
      td {
        border-bottom: none;
      }
      th {
        border-bottom: none;
      }
      .group-row {
        width: 100vw;
        display: block;
        th {
          display: block;
        }
      }
      .row {
        overflow: hidden;
        &.unclickable {
          cursor: not-allowed;
          opacity: 0.6;
        }
        &.credit_item {
          width: 100%;
          .header_product-total {
            display: block;
            text-align: left;
          }
        }
        display:inline-block;
        width: calc(50vw - 10px);
        padding: 15px 20px 20px;
        margin-bottom: 15px;
        background: #fff;
        vertical-align: top !important;
        position: relative;
        .td__libelle-prod-ligne {
          position: static;
          margin-left: 0;
          .td-libelle-prod__ref{
            display: block;
          }
          .header_product-pcb {
            margin-bottom: 20px;
          }
        }

        .td__libelle-prod-icon-new {
          font-size: 49px;
          right: 14px;
          top: 14px;
          left: auto;
          position: absolute;
        }

        .header_product-brand {
          padding: 5px 0 0;
          display: block;
          margin-bottom: 20px;
        }

        .header_product-quantity {
          .input-quantite-cadre {
            height: 34px;
            width: 62px;
          }

        }
        .header_product-wording_credit{
          padding: 0;
        }
      }
    }
    .header_product-image {
      width: 100%;
      display: block;
      text-align: center;
      padding: 0;
      figure {
        width: 140px;
        height: 140px;
      }
    }
    .header_message-wording {
      width: 100%;
    }
    .header_product-wording,
    .header_product-pcb,
    .header_product-price {
      width: 100%;
      display: block;
      padding: 5px 0 0;
      margin-bottom: 20px;
    }
    .header_product-total {
      width: 50%;
      display: inline-block;
      text-align: right;
    }

    .header_product-quantity {
      width: 50%;
      display: inline-block;
    }
  }

  @media all and ${screen.mobileL} {
    table {
      position: relative;
    }
    // hide the headers
    thead {
      display: none;
    }
    .table tbody {
      .row {
        &.unclickable {
          cursor: not-allowed;
          opacity: 0.6;
        }
        width: 100%;
        padding: 20px;
        margin: 5px 0 15px;
        margin-top: 0;
        .td__libelle-prod-icon-new {
          font-size: 38px;
        }
      }
    }
    .header_product-quantity{
      &>div {
        justify-content: flex-start;
      }
    }
  }
`;
