import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BsBoxArrowInDown as UploadIcon } from '@react-icons/all-files/bs/BsBoxArrowInDown';
import { BsCheck as CheckIcon } from '@react-icons/all-files/bs/BsCheck';
import { BsFillExclamationCircleFill } from '@react-icons/all-files/bs/BsFillExclamationCircleFill';
import map from 'lodash.map';
import find from 'lodash.find';
import isarray from 'lodash.isarray';
import * as XLSX from 'xlsx';
import { history, getPath, store } from 'helpers';
import { userService } from 'services';
import { cartActions } from 'actions/Cart';
import ReactTable from 'components/Core/ReactTable/ReactTable';
import { ButtonPrimary } from 'components/Core/Button/Button';
import Alert from 'components/Alert/Alert';
import { selectCartError, selectCurrentCartId, selectCurrentPreorderCartId } from 'selectors/cart';
import { selectCurrentPlatformId } from 'selectors/platform';
import { selectCurrentUser } from 'selectors/user';
import { cartConstants } from 'constants/Cart.constants';
import './OrderFromCsv.scss';
// import ExampleCSV from 'styles/assets/files/csv-example.csv';
import ExampleXLSX from 'styles/assets/files/import_commande_exemple.xlsx';
import Spinner from 'components/Core/Spinner/Spinner';

const OrderFromCsv = ({
  ocrOrderPdf,
  createCart,
  user,
  platformId,
  cartId,
  preorderCartId,
  error,
}) => {
  const [csvfile, setCsvfile] = useState(undefined);
  const [itemsReactTableImport, setItemsReactTableImport] = useState(undefined);
  const [referencesExist, setReferencesExist] = useState(undefined);
  const [visibility, setVisibility] = useState('visible');
  const [isloading, setIsloading] = useState(false);
  const [disable, setDisable] = useState(true);
  const [errorImport, setErrorImport] = useState(false);
  const [referencesRapport, setReferencesRapport] = useState(undefined);
  const [quantityRapport, setQuantityRapport] = useState(undefined);
  const [inputKey, setInputKey] = useState('');

  useEffect(() => {
    if (csvfile !== undefined) {
      const re = /(?:\.([^.]+))?$/;
      const ext = re.exec(csvfile.name)[1];
      if (ext === 'csv') {
        Papa.parse(csvfile, {
          complete(results) {
            const itemsResult = results.data;
            const arrDatas = [];
            const itemsResultSlice = itemsResult.slice(1);
            for (let i = 0; itemsResultSlice[i]; i += 1) {
              if (itemsResultSlice[i][0] && itemsResultSlice[i][1]) {
                const refCsv = itemsResultSlice[i][0];
                const quantityCsv = Number.parseInt(itemsResultSlice[i][1], 10);
                if (!Number.isNaN(quantityCsv) && quantityCsv > 0) {
                  arrDatas.push({
                    reference: refCsv.toString().trim(),
                    quantity: quantityCsv.toString(),
                  });
                }
              }
            }
            setItemsReactTableImport(arrDatas);
          },
        });
      } else if (ext === 'xlsx' || ext === 'xls') {
        const reader = new FileReader();
        reader.readAsBinaryString(csvfile);
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const parsedData = XLSX.utils.sheet_to_json(sheet);
          // clear array
          const arrDatas = [];
          map(parsedData, (a) => {
            const lineObject = {};
            Object.entries(a).forEach(([key, val]) => {
              if (key && val) {
                const keyTrim = key.trim().toLowerCase();
                if (keyTrim === 'référence' || keyTrim === 'réference' || keyTrim === 'reference') {
                  lineObject.reference = val;
                }
                if (
                  keyTrim === 'quantité' ||
                  keyTrim === 'quantités' ||
                  keyTrim === 'quantity' ||
                  keyTrim === 'Colis' ||
                  keyTrim === 'colis'
                ) {
                  lineObject.quantity = val;
                }
              }
            });
            if (lineObject.reference && lineObject.quantity) {
              const refXls = lineObject.reference;
              const quantityXls = Number.parseInt(lineObject.quantity, 10);
              if (!Number.isNaN(quantityXls) && quantityXls > 0) {
                arrDatas.push({
                  reference: refXls.toString().trim(),
                  quantity: quantityXls.toString().trim(),
                });
              }
            }
          });
          setItemsReactTableImport(arrDatas);
        };
      }
    }
  }, [csvfile]);

  const importCsvColumns = React.useMemo(() => [
    {
      id: 'reference',
      Header: 'Référence',
      accessor: (item) => item.reference,
    },
    {
      id: 'quantity',
      Header: 'Nb de colis',
      accessor: (item) => item.quantity,
    },
  ]);

  const responseSwitch = (reason, type) => {
    let reasonText = '';
    let reasonTextHtml = '';
    switch (reason) {
      case 1:
        reasonText = cartConstants.REASON_UNKNOW;
        reasonTextHtml = <i style={{ color: 'red' }}>{cartConstants.REASON_UNKNOW}</i>;
        break;
      case 2:
        reasonText = cartConstants.REASON_REF_NOT_FOUND;
        reasonTextHtml = <i style={{ color: 'red' }}>{cartConstants.REASON_REF_NOT_FOUND}</i>;
        break;
      case 3:
        reasonText = cartConstants.REASON_REF_OUT_OF_STOCK;
        reasonTextHtml = <i style={{ color: 'red' }}>{cartConstants.REASON_REF_OUT_OF_STOCK}</i>;
        break;
      case 4:
        reasonText = cartConstants.REASON_REF_NOT_IN_ASSORTIMENT;
        reasonTextHtml = (
          <i style={{ color: 'red' }}>{cartConstants.REASON_REF_NOT_IN_ASSORTIMENT}</i>
        );
        break;
      case 10:
        reasonText = 'Importé';
        reasonTextHtml = <i style={{ color: 'green' }}>Importé</i>;
        break;
      case 11:
        reasonText = 'Partiellement importé';
        reasonTextHtml = <i style={{ color: 'orange' }}>Partiellement importé</i>;
        break;
      case 12:
        reasonText = 'Non importé';
        reasonTextHtml = <i style={{ color: 'red' }}>Non importé</i>;
        break;
      default:
        reasonTextHtml = '';
    }
    if (type === 'html') {
      return reasonTextHtml;
    }
    return reasonText;
  };

  const rapportCsvColumns = React.useMemo(() => [
    {
      id: 'reference',
      Header: 'Référence',
      accessor: (item) => item.reference,
    },
    {
      id: 'quantity',
      Header: 'Nb de colis',
      accessor: (item) => {
        const elm = find(itemsReactTableImport, { reference: item.reference });
        const quantityRef = item.quantity ? item.quantity : 0;
        return `${quantityRef} / ${elm.quantity}`;
      },
      className: 'text-center',
    },
    {
      id: 'reason',
      Header: 'Status',
      accessor: (item) => responseSwitch(item.reason, 'html'),
    },
  ]);

  const handleChange = (e) => {
    setCsvfile(e.target.files[0]);
    setDisable(false);
  };

  const handlResetImport = () => {
    setCsvfile(undefined);
    setItemsReactTableImport(undefined);
    setReferencesExist(undefined);
    setVisibility('visible');
    setIsloading(false);
    setDisable(true);
    setErrorImport(false);
    setReferencesRapport(undefined);
    setQuantityRapport(undefined);
    const randomString = Math.random().toString(36);
    setInputKey(randomString);
  };

  const handleDownloadRapport = () => {
    let arrResultRapport = [];
    map(referencesRapport, (a) => {
      let arrRef = [];
      const ref = find(itemsReactTableImport, { reference: a.reference });
      arrRef = [responseSwitch(a.reason, 'text'), ...arrRef];
      arrRef = [a.quantity ? a.quantity : 0, ...arrRef];
      arrRef = [ref.quantity, ...arrRef];
      arrRef = [ref.reference, ...arrRef];
      arrResultRapport = [arrRef, ...arrResultRapport];
    });
    const csv = Papa.unparse({
      delimiter: ';',
      fields: ['Référence', 'Nb de colis demandé', 'Nb de colis importé', 'Status'],
      data: arrResultRapport.reverse(),
      download: true,
    });

    const csvData = new Blob([csv]);
    const csvURL = URL.createObjectURL(csvData, { type: 'text/plain' });
    const linkDownload = document.createElement('a');
    linkDownload.href = csvURL;
    linkDownload.setAttribute('download', 'rapport-import.csv');
    linkDownload.click();
  };

  const handleDownloadRapportXls = () => {
    /* generate worksheet and workbook */
    let arrResultRapport = [];
    map(referencesRapport, (a) => {
      let arrRef = [];
      const ref = find(itemsReactTableImport, { reference: a.reference });
      arrRef = [responseSwitch(a.reason, 'text'), ...arrRef];
      arrRef = [a.quantity ? a.quantity : 0, ...arrRef];
      arrRef = [ref.quantity, ...arrRef];
      arrRef = [ref.reference, ...arrRef];
      arrResultRapport = [arrRef, ...arrResultRapport];
    });
    const datas = arrResultRapport.reverse();
    const worksheet = XLSX.utils.json_to_sheet(datas);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Dates');

    /* fix headers */
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [['Référence', 'Nb de colis demandé', 'Nb de colis importé', 'Status']],
      { origin: 'A1' }
    );

    /* set column width */
    worksheet['!cols'] = [{ wch: 12 }, { wch: 15 }, { wch: 15 }, { wch: 23 }];

    /* create an XLSX file and try to save to rapport.xlsx */
    XLSX.writeFile(workbook, 'rapport.xlsx', { compression: true });
  };

  const importCSV = () => {
    setIsloading(true);
    setDisable(true);
    createCart(
      user,
      platformId,
      cartId,
      preorderCartId,
      itemsReactTableImport,
      setReferencesExist,
      setReferencesRapport,
      setQuantityRapport,
      setIsloading,
      setVisibility,
      setErrorImport
    );
  };
  const goToDashboard = () => {
    history.push(userService.getHomepagePath());
  };

  const goToCart = (id) => {
    if (id) {
      history.push(getPath('cart').replace(':id', id).replace('/:temperature?', ''));
    }
  };

  const limitReferences = 2401;

  return (
    <div className="page-order-from-csv">
      <h2 className="csv-title">Créez votre panier en important un fichier CSV</h2>
      <div className="csv-col">
        <div className="csv-description">
          <p>
            Vous pouvez créer votre panier en utilisant un fichier au format CSV ou excel (xls,
            xlsx).
            <br />
            <br />
            Le fichier doit comporter deux informations :<br />
            la <b>référence</b> de l’article et la <b>quantité</b> colis.
            <br />
            <br />
            Le fichier ne doit pas dépasser <b>{limitReferences - 1}</b> codes articles.
            <br />
            <br />
            Pour créer un fichier CSV, utilisez le menu “Enregistrer au format CSV” d’Excel.
            <br />
            <br />
            Vous pouvez télécharger un modèle de fichier{' '}
            <Link to={ExampleXLSX} target="_blank" download>
              ici
            </Link>
            .
          </p>
        </div>
        <div className="csv-form">
          <div className="orderFromCsv__errors">
            {error && error.message && <Alert text={error.message} />}
          </div>
          <div className="csv-form-container">
            <div className="csv-file-input-container">
              <input
                className="csv-file-input"
                type="file"
                key={inputKey || ''}
                // ref={(input) => {
                //   const fileInput = input;
                // }}
                name="file"
                placeholder={null}
                onChange={handleChange}
                accept=".xls,.xlsx,.csv,text/csv"
              />
            </div>
            {csvfile === undefined && (
              <div className="csv-message-input">
                <UploadIcon />
                <span>Déposer ou Sélectionner un fichier</span>
              </div>
            )}
            {csvfile !== undefined && (
              <>
                {itemsReactTableImport &&
                itemsReactTableImport.length > 0 &&
                itemsReactTableImport.length < limitReferences ? (
                  <div className="csv-message-input-2">
                    <CheckIcon />
                    <span>Votre fichier "{csvfile.name}" est prêt à être importé</span>
                    <div className={`bloc-btn ${visibility}`}>
                      <ButtonPrimary disabled={disable} onClick={importCSV}>
                        Importer !
                      </ButtonPrimary>
                      <ButtonPrimary onClick={handlResetImport}>Annuler</ButtonPrimary>
                    </div>
                  </div>
                ) : (
                  <div className="csv-message-input-2">
                    <BsFillExclamationCircleFill />
                    <span>Votre fichier "{csvfile.name}" ne peux être importé</span>
                    <div className={`bloc-btn ${visibility}`}>
                      <ButtonPrimary onClick={handlResetImport}>
                        Importer un nouveau fichier
                      </ButtonPrimary>
                    </div>
                  </div>
                )}
              </>
            )}
            {referencesRapport !== undefined && (
              <div className="file-imported">
                <CheckIcon />
                <span>Votre fichier "{csvfile.name}" à été importé</span>
                <br />
                <br />
                <div className="bloc-button-flex">
                  <ButtonPrimary onClick={handlResetImport}>
                    Importer un nouveau fichier
                  </ButtonPrimary>
                  {/* <ButtonPrimary onClick={goToDashboard}>Catalogue</ButtonPrimary> */}
                </div>
              </div>
            )}
            {errorImport === true && (
              <div className="file-imported-error">
                <BsFillExclamationCircleFill />
                <span>
                  Une erreur s'est produite !
                  <br />
                  Votre fichier "{csvfile.name}" n'a pu être importé
                </span>
                <br />
                <br />
                <div className="bloc-button-flex">
                  <ButtonPrimary onClick={handlResetImport}>
                    Importer un nouveau fichier
                  </ButtonPrimary>
                  {/* <ButtonPrimary onClick={goToDashboard}>Catalogue</ButtonPrimary> */}
                </div>
              </div>
            )}
            {isloading && (
              <div className="is-loading">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="csv-result">
        {referencesRapport !== undefined ? (
          <div className="csv-rapport">
            <div className="csv-content">
              <ReactTable
                columns={rapportCsvColumns}
                data={referencesRapport}
                isLoading={!referencesRapport}
              />
            </div>
            {quantityRapport !== undefined && (
              <div className="text-rapport">
                {quantityRapport && quantityRapport.quantityImported > 1 && (
                  <span className="green">
                    {quantityRapport.quantityImported} références ont été importées.
                  </span>
                )}
                {quantityRapport && quantityRapport.quantityImported === 1 && (
                  <span className="green">
                    {quantityRapport.quantityImported} référence a été importée.
                  </span>
                )}
                {quantityRapport && quantityRapport.quantityPartialImported > 1 && (
                  <span className="orange">
                    {quantityRapport.quantityPartialImported} références ont été partiellement
                    importées.
                  </span>
                )}
                {quantityRapport && quantityRapport.quantityPartialImported === 1 && (
                  <span className="orange">
                    {quantityRapport.quantityPartialImported} référence a été partiellement
                    importée.
                  </span>
                )}
                {quantityRapport && quantityRapport.quantityNotImported > 1 && (
                  <span className="red">
                    {quantityRapport.quantityNotImported} références n'ont pas été importées.
                  </span>
                )}
                {quantityRapport && quantityRapport.quantityNotImported === 1 && (
                  <span className="red">
                    {quantityRapport.quantityNotImported} référence n'a pas été importée.
                  </span>
                )}
                <div className="download-rapport">
                  <ButtonPrimary onClick={handleDownloadRapport}>
                    Télécharger le rapport csv
                  </ButtonPrimary>
                  <ButtonPrimary onClick={handleDownloadRapportXls}>
                    Télécharger le rapport xls
                  </ButtonPrimary>
                </div>
                <div className="go-to-cart">
                  <ButtonPrimary
                    onClick={() => {
                      goToCart(cartId);
                    }}
                  >
                    Aller au panier
                  </ButtonPrimary>
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            {itemsReactTableImport !== undefined &&
              itemsReactTableImport.length >= limitReferences && (
                <div className="csv-import">
                  <div className="nb-refs">
                    <b>{itemsReactTableImport.length}</b>{' '}
                    {itemsReactTableImport.length > 0 ? `Références` : `Référence`}
                  </div>
                  <div className="csv-content error-max">
                    <p>Le maximum de codes articles à importer est de {limitReferences - 1}.</p>
                  </div>
                </div>
              )}
            {itemsReactTableImport !== undefined && itemsReactTableImport.length < limitReferences && (
              <div className="csv-import">
                <div className="nb-refs">
                  <b>{itemsReactTableImport.length}</b>{' '}
                  {itemsReactTableImport.length > 0 ? `Références` : `Référence`} à importer
                </div>
                <div className="csv-content">
                  <ReactTable
                    columns={importCsvColumns}
                    data={itemsReactTableImport}
                    isLoading={!itemsReactTableImport}
                  />
                </div>
              </div>
            )}
          </>
        )}
        <div className="clearfix" />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createCart: async (
    user,
    platformId,
    cartId,
    preorderCartId,
    itemsByReference,
    setReferencesExist,
    setReferencesRapport,
    setQuantityRapport,
    setIsloading,
    setVisibility,
    setErrorImport,
    cart,
    updateCart
  ) => {
    const [result1, result2] = await Promise.all([
      cartActions.renewCurrentCartWithReferences(dispatch, platformId, cartId, itemsByReference),
      cartActions.renewCurrentCartWithReferences(
        dispatch,
        platformId,
        preorderCartId,
        itemsByReference,
        cartConstants.CART_STATUS_CURRENT_PREORDER
      ),
      cartActions.updateCart(cart, {}),
    ]);
    if (result1 && result1.id && result2 && result2.id) {
      const [resultReferencesExist, resultReferencesExistPreorder] = await Promise.all([
        cartActions.renewCurrentCartWithReferencesExist(dispatch, {
          id: result1.id,
          items_by_reference: itemsByReference,
        }),
        cartActions.renewCurrentCartWithReferencesExist(dispatch, {
          id: result2.id,
          items_by_reference: itemsByReference,
        }),
      ]);
      if (isarray(resultReferencesExist) && isarray(resultReferencesExistPreorder)) {
        let resultNotInCart = [];
        let resultIsInCart = [];
        map(resultReferencesExist, (a) => {
          const ref = find(resultReferencesExistPreorder, { reference: a.reference });
          if (a.is_in_cart === 0 && ref.is_in_cart === 0) {
            resultNotInCart = [a, ...resultNotInCart];
          } else {
            resultIsInCart = [a, ...resultIsInCart];
          }
        });

        let resultRapportCart = [];
        const quantityRefs = {
          quantityImported: 0,
          quantityNotImported: 0,
          quantityPartialImported: 0,
        };
        map(resultReferencesExist.reverse(), (a) => {
          const arrRef = a;
          const ref = find(resultReferencesExistPreorder, { reference: a.reference });
          if (a.is_in_cart === 0 && ref.is_in_cart === 0) {
            arrRef.quantity = 0;
            const q = quantityRefs.quantityNotImported + 1;
            quantityRefs.quantityNotImported = q;
            resultRapportCart = [arrRef, ...resultRapportCart];
          } else {
            const refQ = find(itemsByReference, { reference: a.reference });
            const quantityAsked = Number.parseInt(refQ.quantity, 10);
            const quantityOrder = Number.parseInt(a.quantity, 10)
              ? Number.parseInt(a.quantity, 10)
              : 0;
            const quantityPreOrder = Number.parseInt(ref.quantity, 10)
              ? Number.parseInt(ref.quantity, 10)
              : 0;
            if (
              quantityOrder + quantityPreOrder < quantityAsked &&
              quantityOrder + quantityPreOrder !== 0
            ) {
              arrRef.reason = 11;
              const q = quantityRefs.quantityPartialImported + 1;
              quantityRefs.quantityPartialImported = q;
            } else if (quantityOrder + quantityPreOrder === quantityAsked) {
              arrRef.reason = 10;
              const q = quantityRefs.quantityImported + 1;
              quantityRefs.quantityImported = q;
            } else {
              arrRef.reason = 12;
              const q = quantityRefs.quantityNotImported + 1;
              quantityRefs.quantityNotImported = q;
            }
            arrRef.quantity = quantityOrder + quantityPreOrder;
            resultRapportCart = [arrRef, ...resultRapportCart];
          }
        });
        setReferencesRapport(resultRapportCart);
        setQuantityRapport(quantityRefs);
        setVisibility('hidden');
        setReferencesExist(resultNotInCart);
        setIsloading(false);
      }
    } else {
      setErrorImport(true);
      setIsloading(false);
    }
    setIsloading(false);
    return 'error';
  },
});

const mapStateToProps = (state) => ({
  platformId: selectCurrentPlatformId(state),
  cartId: selectCurrentCartId(state),
  preorderCartId: selectCurrentPreorderCartId(state),
  error: selectCartError(state),
  user: selectCurrentUser(state),
});

OrderFromCsv.propTypes = {
  platformId: PropTypes.number,
  createCart: PropTypes.func,
  cartId: PropTypes.number,
  preorderCartId: PropTypes.number,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  user: PropTypes.shape({
    has_dashboard_v2: PropTypes.bool,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderFromCsv);
