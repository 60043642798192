import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import WarningIcon from 'components/CommercialOrderForm/WarningIcon';
import CommercialOrderDropdownSelect from 'components/CommercialOrderForm/CommercialOrderDropdownSelect/CommercialOrderDropdownSelect';
import { cartActions, cartItemsActions, clientActions, productActions } from 'actions';
import { selectClientCollectionByFilter } from 'selectors/client';
import { selectIsLoadingFromCatalog, selectItemsFromCatalog } from 'selectors/product';
import { selectCurrentPlatform } from 'selectors/platform';
import { formatPrice } from 'components/Aggrid/cellRenderers';
import { CommercialOrderFormWrapper } from 'components/CommercialOrderForm/CommercialOrderForm.style';
import { getProductImageFromEan } from 'helpers/Product';
import { dateTimeToFormatedDate, getValueOfAttribut, history } from 'helpers';
import { userService } from 'services';
import ClientDropdownSelect from 'components/Core/DropdownSelect/ClientDropdownSelect/ClientDropdownSelect';
import ClientLockedCell from 'components/Table/Cells/Client/ClientLockedCell';
import { toastActions } from 'actions/Toast';
import { toastConstants } from 'constants/Toast.constants';
import {
  cartConstants,
  clientStatusConstants,
  platformConstants,
  productConstants,
} from '../../constants';

const { STATUS_ACTIVE } = clientStatusConstants;
const { NEGOCIABLE_PERCENTAGE } = platformConstants;

const CommercialOrderForm = ({
  fetchClients,
  storeClientList,
  platform,
  fetchCatalogItems,
  itemList,
  setSelectedClient,
  selectedClient,
  setCartItems,
  cartItems,
  setShouldBlockNavigation,
  createCartFromItems,
  addItemToCart,
  cart,
  saveItem,
  selectedItem,
  setSelectedItem,
  setIsCartInitialized,
  pushToast,
}) => {
  const productSelectRef = useRef(null);
  const productQuantityRef = useRef(null);
  const clientDropdownSelectRef = useRef(null);
  const prevItemListProps = useRef(null);
  const [productQuantity, setProductQuantity] = useState('');
  const [clientList, setClientList] = useState([]);
  const [priceNegociated, setPriceNegociated] = useState(0);
  let timer = null;
  const { id: platformId } = platform;
  const negociablePercentage = getValueOfAttribut(platform.attributs, NEGOCIABLE_PERCENTAGE);

  useEffect(() => {
    fetchClients({ platform: platformId });

    if (clientDropdownSelectRef.current) {
      clientDropdownSelectRef.current.focus();
    }

    if (history.location.state && history.location.state.cart) {
      const historyCart = history.location.state.cart;
      const { client } = historyCart.user;

      fetchCatalogItems(platformId, client.id);
      setSelectedClient({ ...client, customers: [historyCart.user, ...client.customers] });
      setCartItems(
        historyCart.cart_items.map((cartItem) => ({
          ...cartItem.item,
          quantity: cartItem.quantity,
          price_negociated: cartItem.price !== cartItem.applicable_price ? cartItem.price : null,
        }))
      );
    }
  }, []);

  useEffect(() => {
    if (storeClientList && storeClientList.items) {
      setClientList({
        ...storeClientList,
        items: storeClientList.items.filter((client) => client.status === STATUS_ACTIVE),
      });
    }
  }, [storeClientList]);

  useEffect(() => {
    if (itemList.length && prevItemListProps.current && prevItemListProps.current.length === 0) {
      productSelectRef.current.focus();
    }
    prevItemListProps.current = itemList;
  }, [itemList]);

  useEffect(() => {
    if (selectedItem) {
      const { negociable } = selectedItem;
      if (negociablePercentage && negociable) {
        setPriceNegociated(formatPrice(selectedItem.applicable_price));
      }
      productQuantityRef.current.focus();
      productQuantityRef.current.select();
    }
    if (!selectedItem) {
      setProductQuantity('');
    }
  }, [selectedItem]);

  let clientOptions = [];
  if (clientList && clientList.items) {
    clientOptions = clientList.items.map((client) => ({
      label: `${client.siret.padEnd(12, '\xa0')} ${client.name}`,
      value: client,
    }));
  }

  const onQuantityChange = (event) => {
    const re = /^[0-9\b]+$/;

    if (
      selectedItem &&
      (event.target.value === '' || re.test(event.target.value)) &&
      Number(event.target.value) < 1000
    ) {
      setProductQuantity(event.target.value);
    }
  };

  const handleAddItem = () => {
    let newCartItem = { ...selectedItem, quantity: productQuantity };

    if (priceNegociated) {
      const priceNegociatedUnformated = parseFloat(priceNegociated.replace(/,/g, '.'));
      if (
        priceNegociatedUnformated <
        selectedItem.price_weighted + (selectedItem.price_weighted * negociablePercentage) / 100
      ) {
        pushToast(
          toastConstants.TYPE_ERROR,
          'Le prix négocié ne doit pas être inférieur au prix planché'
        );

        return null;
      }

      if (selectedItem.applicable_price !== priceNegociatedUnformated) {
        newCartItem = { ...newCartItem, price_negociated: priceNegociatedUnformated };
      }
    }

    if (selectedItem && !cart) {
      userService.loginAs(selectedClient.customers[0].username);
      createCartFromItems([newCartItem], null, null, cartConstants.CART_COMMERCIAL_TYPE);
      userService.logoutAs();
      setIsCartInitialized(true);
    }
    if (cartItems.length && cart && selectedItem && productQuantity) {
      let cartObj = selectedItem;
      if (priceNegociated) {
        const priceNegociatedUnformated = parseFloat(priceNegociated.replace(/,/g, '.'));
        if (selectedItem.applicable_price !== priceNegociatedUnformated) {
          cartObj = { ...selectedItem, price_negociated: priceNegociatedUnformated };
        }
      }
      addItemToCart(cart.id, cartObj, productQuantity);

      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        saveItem(cart.id, { id: selectedItem.id });
      }, 1000);
    }

    const newCartItems = [...cartItems.filter((item) => item.id !== selectedItem.id), newCartItem];

    setCartItems(newCartItems);
    setSelectedItem(null);
    setProductQuantity('');
    setPriceNegociated(null);
    productSelectRef.current.focus();

    return null;
  };

  const handleNegociablePriceChange = (event) => {
    const priceFormat = /^$|^[0-9]+([.,])?([0-9]+)?$/;

    if (priceFormat.test(event.target.value)) {
      setPriceNegociated(event.target.value);
    }
  };

  return (
    <CommercialOrderFormWrapper>
      <div className="commercial-order-form__flex">
        <form>
          <div className="commercial-order-form__client-line">
            <div>
              <label className="commercial-order-form__bold">Client</label>
              {/* <Select */}
              {/*  className="commercial-order-form__react-select" */}
              {/*  classNamePrefix="react-select" */}
              {/*  placeholder="" */}
              {/*  isDisabled={selectedClient} */}
              {/*  options={clientOptions} */}
              {/*  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }} */}
              {/*  onChange={(event) => { */}
              {/*    setSelectedClient(event); */}
              {/*    fetchCatalogItems(platformId, event.value.id); */}
              {/*    setShouldBlockNavigation(true); */}
              {/*  }} */}
              {/*  value={selectedClient} */}
              {/*  autoFocus */}
              {/* /> */}
              <ClientDropdownSelect
                clientList={clientList && clientList.items}
                selectedItem={selectedClient}
                setSelectedItem={(value) => {
                  setSelectedClient(value);
                  if (value) {
                    fetchCatalogItems(platformId, value.id);
                    setShouldBlockNavigation(true);
                  }
                }}
                inputRef={clientDropdownSelectRef}
                isLoading={clientList && clientList.isLoading}
                isDisabled={selectedClient}
              />
            </div>
            <div className="commercial-order__locked-icon">
              <div />
              <div>{selectedClient && selectedClient.locked && <ClientLockedCell />}</div>
            </div>
            <div>
              <div className="commercial-order-form__bold">Adresse</div>
              <div className="commercial-order-form__client-values">
                {selectedClient && selectedClient.address}
              </div>
            </div>
            <div>
              <div className="commercial-order-form__bold">Ville</div>
              <div className="commercial-order-form__client-values">
                {selectedClient && `${selectedClient.zipcode} ${selectedClient.city}`}
              </div>
            </div>
          </div>
          <div className="commercial-order-form__product-line">
            <div className="commercial-order-form__select-container">
              <label className="commercial-order-form__bold">Article</label>
              <CommercialOrderDropdownSelect
                itemList={itemList}
                isDisabled={!selectedClient || !itemList.length}
                inputRef={productSelectRef}
                selectedItem={selectedItem}
                setSelectedItem={(value) => {
                  setProductQuantity(1);
                  setSelectedItem(value);
                  setPriceNegociated(null);
                }}
                withFilter
              />
            </div>
            <div>
              <div className="commercial-order-form__bold">Stk colis</div>
              <div
                className="commercial-order-form__product-values"
                style={{ color: selectedItem && selectedItem.value_packing < 1 ? 'red' : '' }}
              >
                {selectedItem && selectedItem.value_packing ? selectedItem.value_packing : 0}
              </div>
            </div>
            <div>
              <div className="commercial-order-form__bold">Stk UC</div>
              <div
                className="commercial-order-form__product-values"
                style={{ color: selectedItem && selectedItem.stock.value_cu < 1 ? 'red' : '' }}
              >
                {selectedItem && selectedItem.stock.value_cu ? selectedItem.stock.value_cu : 0}
              </div>
            </div>
            <div>
              <div className="commercial-order-form__bold">PCB</div>
              <div className="commercial-order-form__product-values">
                {selectedItem && selectedItem.pcb ? selectedItem.pcb : 0}
              </div>
            </div>
            <div>
              <div className="commercial-order-form__bold">Prix Colis</div>
              <div className="commercial-order-form__product-values">
                {selectedItem && selectedItem.has_promotion && (
                  <strike style={{ color: 'red', marginRight: '5px' }}>
                    <span style={{ color: 'grey' }}>
                      {formatPrice(selectedItem.price * selectedItem.pcb)} €
                    </span>
                  </strike>
                )}
                {selectedItem &&
                  `${formatPrice(
                    ((priceNegociated && Number(priceNegociated.replace(',', '.'))) ||
                      selectedItem.applicable_price) * selectedItem.pcb
                  )} €`}
                {!selectedItem && '0'}
              </div>
            </div>
            <div>
              <div className="commercial-order-form__bold">Prix UC</div>
              <div className="commercial-order-form__product-values">
                {selectedItem && selectedItem.has_promotion && (
                  <strike style={{ color: 'red', marginRight: '5px' }}>
                    <span style={{ color: 'grey' }}>{formatPrice(selectedItem.price)} €</span>
                  </strike>
                )}
                {selectedItem &&
                  (negociablePercentage > 0 && selectedItem.negociable ? (
                    <div className="commercial-order-form__negociable-input">
                      <input
                        className="commercial-order-form__quantity-input"
                        type="text"
                        value={priceNegociated}
                        onChange={handleNegociablePriceChange}
                        onFocus={(event) => event.target.select()}
                        onKeyDown={(event) => {
                          event.stopPropagation();
                          if (event.key === 'Enter' && productQuantity && productQuantity > 0)
                            handleAddItem();
                        }}
                      />
                      <small className="nowrap">
                        {formatPrice(
                          selectedItem.price_weighted +
                            (selectedItem.price_weighted * negociablePercentage) / 100
                        )}{' '}
                        € min
                      </small>
                    </div>
                  ) : (
                    `${formatPrice(selectedItem.applicable_price)} €`
                  ))}
                {!selectedItem && '0'}
              </div>
            </div>
            <div>
              <div className="commercial-order-form__bold">DLV</div>
              <div className="commercial-order-form__product-values">
                {selectedItem &&
                  (selectedItem.sale_deadline ? (
                    dateTimeToFormatedDate(selectedItem.sale_deadline)
                  ) : (
                    <span> - </span>
                  ))}
                {!selectedItem && '-'}
              </div>
            </div>
            <div>
              <label className="commercial-order-form__bold">Qté colis</label>
              <input
                className="commercial-order-form__quantity-input"
                type="text"
                value={productQuantity}
                ref={productQuantityRef}
                onChange={onQuantityChange}
                onFocus={(event) => event.target.select()}
                disabled={!selectedItem}
                onKeyDown={(event) => {
                  event.stopPropagation();
                  if (event.key === 'Enter' && productQuantity && productQuantity > 0)
                    handleAddItem();
                }}
              />
            </div>
            {selectedItem && selectedItem.stock.value_packing < productQuantity && (
              <div className="commercial-order-form__warning-icon">
                <label> </label>
                <WarningIcon />
              </div>
            )}
            {selectedItem && selectedItem.pallet_layer > 0 && selectedItem.pallet_package > 0 && (
              <div className="commercial-order-form__pallet_info">
                <div>colis/couche : {selectedItem.pallet_package} colis</div>
                <div>
                  colis/palette : {selectedItem.pallet_package * selectedItem.pallet_layer} colis
                </div>
              </div>
            )}
          </div>
        </form>
        <div className="commercial-order-form__product-image">
          {selectedItem && (
            <img
              src={getProductImageFromEan(selectedItem.ean13, productConstants.IMAGE_SIZE_LARGE)}
              alt={`${selectedItem.ean13.value}`}
            />
          )}
        </div>
      </div>
    </CommercialOrderFormWrapper>
  );
};

CommercialOrderForm.propTypes = {
  fetchClients: PropTypes.func,
  storeClientList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  platform: PropTypes.object,
  fetchCatalogItems: PropTypes.func,
  itemList: PropTypes.array,
  setSelectedClient: PropTypes.func,
  selectedClient: PropTypes.object,
  setCartItems: PropTypes.func,
  cartItems: PropTypes.array,
  setShouldBlockNavigation: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

const mapStateToProps = (state) => {
  const platform = selectCurrentPlatform(state);

  return {
    storeClientList: selectClientCollectionByFilter(state, { platform: platform.id }),
    itemList: selectItemsFromCatalog(state, { aggrid: true }).filter((item) => !item.is_preorder),
    itemListIsLoading: selectIsLoadingFromCatalog(state, { aggrid: true }),
    platform,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchClients: (filter) => dispatch(clientActions.fetchClients(filter, 0, 10000)),
  fetchCatalogItems: (platformId, clientId, categoryIds = []) =>
    dispatch(productActions.fetchAllProducts(platformId, categoryIds, 4200, clientId)),
  createCartFromItems: (cartItems, deliveryDates, comment, cartType) =>
    dispatch(cartActions.createCartFromItems(cartItems, deliveryDates, comment, cartType)),
  addItemToCart: (cartId, item, quantity, cartName) =>
    dispatch(cartItemsActions.addItem(cartId, item, Number(quantity), {}, cartName)),
  saveItem: (cartId, cartItem) => {
    dispatch(cartItemsActions.saveItem(cartId, cartItem));
  },
  pushToast: (type, message, title = '') =>
    dispatch(toastActions.addToast({ type, message, title, duration: 1 })),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommercialOrderForm);
