import { userService } from 'services';
import { history, getPath, store } from 'helpers';
import { platformActions } from 'actions';
import { selectCurrentPlatform } from 'selectors/platform';
import {
  clientStatusConstants,
  userConstants,
  platformConstants,
  aggridFilterConstants,
} from '../constants';

const { HAS_TECHNICAL_SHEETS_KEY } = platformConstants;
const { HAS_DRY_PRODUCTS_KEY, HAS_FROZEN_PRODUCTS_KEY, HAS_FRESH_PRODUCTS_KEY } = platformConstants;
const { FILTER_DRY, FILTER_FRESH, FILTER_FROZEN } = aggridFilterConstants;

export function getValueOfAttribut(attributs, key) {
  if (attributs && attributs.length === 0) {
    return null;
  }
  const attribut = attributs && attributs.find((element) => element.key === key);

  return attribut ? attribut.value : null;
}

export const getPlatformFromListById = (platformList, platformId) =>
  platformList.find(({ id }) => Number(id) === Number(platformId)) || {};

export function getUserPlatforms(user) {
  const { type, platform, platforms: userPlatforms, client } = user;

  if (type === userConstants.TYPE_CUSTOMER) {
    const { client_status: clientStatus, platforms: clientPlatforms } = client;

    return (
      clientStatus
        .filter(
          ({ status_preorder: statusPreorder, status_catalog: statusCatalog }) =>
            statusPreorder === clientStatusConstants.STATUS_ACTIVE ||
            statusCatalog === clientStatusConstants.STATUS_ACTIVE
        )
        .map((status) => status.platform.id)
        // client status platforms are missing the attributes
        .map((id) => clientPlatforms.find((p) => p && Number(p.id) === Number(id)))
    );
  }

  if (type === userConstants.TYPE_SUPER_ADMIN) {
    return userPlatforms;
  }

  return [platform];
}

export function getEffectivePlatform(user) {
  const platforms = getUserPlatforms(user);
  const storagePlatform = localStorage.getItem('platform');
  const { type } = user;

  if (type === userConstants.TYPE_CUSTOMER || type === userConstants.TYPE_SUPER_ADMIN) {
    if (storagePlatform) {
      const platform = platforms.find((p) => p && Number(storagePlatform) === Number(p.id));
      if (platform) {
        return platform;
      }
    }
    if (type === userConstants.TYPE_CUSTOMER) {
      if (platforms.length === 1) {
        return platforms[0];
      }
      return null;
    }
  }

  return platforms[0];
}

export function checkPlatformAllowsTechnicalSheets(selectedPlatform) {
  const { attributs } = selectedPlatform || {};

  return !!Number(getValueOfAttribut(attributs || [], HAS_TECHNICAL_SHEETS_KEY));
}

export function getAvailableTemperatures(platform) {
  const { attributs } = platform;
  const hasDryProducts = Number(getValueOfAttribut(attributs || [], HAS_DRY_PRODUCTS_KEY));
  const hasFreshProducts = Number(getValueOfAttribut(attributs || [], HAS_FRESH_PRODUCTS_KEY));
  const hasFrozenProducts = Number(getValueOfAttribut(attributs || [], HAS_FROZEN_PRODUCTS_KEY));

  const availableTemperatureFilters = [];

  if (hasDryProducts) {
    availableTemperatureFilters.push({ title: 'SEC', value: FILTER_DRY });
  }
  if (hasFreshProducts) {
    availableTemperatureFilters.push({ title: 'FRAIS', value: FILTER_FRESH });
  }
  if (hasFrozenProducts) {
    availableTemperatureFilters.push({ title: 'FROID', value: FILTER_FROZEN });
  }

  return availableTemperatureFilters;
}

export function changePlatform(platformId, user) {
  localStorage.setItem('platform', Number(platformId));
  store.dispatch(platformActions.setPlatform(Number(platformId)));
  const { information } = user;

  if (user && information) {
    const { type } = information;
    switch (type) {
      case userConstants.TYPE_SUPER_ADMIN:
        // eslint-disable-next-line no-restricted-globals
        location.href = getPath('configuration');
        break;
      case userConstants.TYPE_CUSTOMER:
        // eslint-disable-next-line no-restricted-globals
        location.href = userService.getHomepagePath();
        break;
      default:
        // eslint-disable-next-line no-restricted-globals
        location.href = getPath('dashboardAdmin');
        break;
    }
  }
}
