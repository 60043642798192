import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const TooltipWrapper = styled.div`
  min-height: 20px;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  background: #FFF;
  position: absolute;
  border: 1px solid #DBDBDB;
  border-radius: 2px;
  box-shadow: 0 0 28px 0 rgba(0, 0, 0, 0.2),
              0 0 4px 0 rgba(0, 0, 0, 0.1),
              inset 0 0 0 1px rgba(255, 255, 255, 0.2);
              
  top: ${(props) => props.coordY || 0}px;
  left: ${(props) => props.coordX || 0}px;
  
  transform: translate(0, calc(-100% - 10px));

  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  animation: ${(props) => (props.isVisible ? fadeIn : fadeOut)} 0.15s linear;
  animation-delay: ${(props) => (props.isVisible ? '1s' : '0')};
  animation-fill-mode: forwards;
  transition: visibility 0.15s linear;
  transition-delay: ${(props) => (props.isVisible ? '1s' : '0')};

  &:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border: 10px solid transparent;

  &:before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border: 10px solid transparent;
  }

  .tooltip__label {
    font-size: 10px;
    font-weight: 400;
    white-space: nowrap;
    color: #4a4a4a;
  }
`;

export default TooltipWrapper;
